//import { configure } from "mobx"
import MainViewStore from "./mainViewStore"
import ImageUploadStore from "./imageUploadStore"
import GeometryStore from "./geometryStore"

/*configure({
    useProxies: "never"
  })*/

class MainStore {
    mainViewStore: MainViewStore
    imageUploadStore: ImageUploadStore
    geometryStore: GeometryStore
    imagePopupStore: GeometryStore

    constructor() {
        console.log("init stores...")
        this.mainViewStore = new MainViewStore();
        this.imageUploadStore = new ImageUploadStore();
        this.geometryStore = new GeometryStore("geometry");
        this.imagePopupStore = new GeometryStore("imagePopup");
    }

    /** сброс состояния */
    logout() {
        this.mainViewStore.reset();
        this.imageUploadStore.reset();
        this.geometryStore.reset();
        this.imagePopupStore.reset();
    }
}


const mainStore = new MainStore();

export default mainStore;
