import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { TableCell, TableSortLabel, Theme } from '@material-ui/core';
import { ColumnDescription } from './historyTable.js'

const styles = createStyles((theme: Theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHeader: {
    textAlign: "center"
  }
}))

export interface TableHeadersProps {
  /** array with headers */
  headers: Array<ColumnDescription>,
  /** ascending/descending order */
  order: 'asc' | 'desc',
  /** sort field  */
  orderBy: string,
  /**  sort click eveny handler */
  sort: (id: string) => void,

  classes: any
}

/** Component for rendering table headers */
const TableHeaders = (props: TableHeadersProps) => {
  console.log("render TableHeaders");
  let classes = props.classes;
  return (
    <>
      {props.headers.map(cell => (
        <TableCell key={cell.id} className={classes.tableHeader}>
          {cell.sorted &&
            <TableSortLabel
              active={props.orderBy === cell.id}
              direction={props.order}
              //onClick={(e)=>this.handleRequestSort(cell.id)}
              onClick={(e) => props.sort(cell.id)}
            >{cell.label}
              {props.orderBy === cell.id ? (
                <span className={classes.visuallyHidden}>
                  {props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          }
          {!cell.sorted &&
            cell.label
          }
        </TableCell>
      ))}
    </>
  )
}

export default withStyles(styles)(TableHeaders);
