import React, { useState, useEffect } from 'react'
import i18next from 'i18next';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, TextField, Theme, Grid } from '@material-ui/core';
import mainStore from "./../stores/mainStore"
import FoodImage from './foodImage';
import GeometryStore from "./../stores/geometryStore"
import { observer } from "mobx-react";

const styles = (theme: Theme) => createStyles({
  popup: {
    marginRight: 100,
    width: "800px"
  },
  panel: {
    textAlign: "center",
    marginTop: 10
  },
  button: {
    width: "150px",
    marginBottom: "20px",
    textAlign: "center"
  }
});


export interface ImagePopupProps {
  /** is dialog opened */
  open: boolean
  /**  Close event handler */
  onClose: () => void,
  /** */
  store: GeometryStore
  classes: any
}


/**
 *  Popup dialog with buttons
 */
const ImagePopup = observer((props: ImagePopupProps) => {

  //const geometryStore = mainStore.geometryStore
  const geometryStore = props.store
  const store = mainStore.mainViewStore

  const imageLoaded = () => {
    console.log("image loaded...");
    geometryStore.updateGeometry(mainStore.geometryStore.geometry);
    if (mainStore.geometryStore.highlight)
      geometryStore.setHighlight(mainStore.geometryStore.highlight.key);
  }

  const classes = { ...props.classes };

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"lg"}
      fullScreen
    >
      <Grid container direction="column">
        <Grid item style={{ marginTop: 20 }}>
          <FoodImage image={store.image!} width={window.screen.width * 0.9} height={window.screen.height * 0.8} useCanvas={true} shared={store.isShared} geometry={geometryStore.geometry} highlight={geometryStore.highlight} imageLoaded={imageLoaded} store={geometryStore} />
        </Grid>
        <Grid item className={classes.panel}>
          <Button variant="contained" onClick={() => { props.onClose() }} color="primary" className={classes.button}>
            {i18next.t('OK')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
})

export default withStyles(styles)(ImagePopup);