import { action, makeAutoObservable, makeObservable, observable, computed } from "mobx"
import { cloneDeep } from "lodash"
import { pointIsInPoly, shiftPolygon } from './../utils';
import { CanvasHelper } from 'canvas';
//import mainStore from "./mainStore"
import { ModelResponse, RecognitionResult } from "./mainViewStore"
import { ModelResponseDTO, RecognitionResultDTO } from "dto/fpsDTO";

export interface AnnotationGeometry {
  /** bounding box */
  bbox: Array<Array<number>>,
  /** bounding polygons */
  polygons?: Array<Array<number>>
  /** показывать геометрию? */
  display: boolean
  /** название блюда/ингредиента*/
  name: string
  /** heatmap*/
  heatmap?: Array<Array<number>>
  /** индекс в массиве результатов */
  //index: number
  /** название frs */
  frs_name: string,
  /** */
  key: string
}

export interface HighlightItem {
  //index: number
  //frs_name: string
  key: string
}

export default class GeometryStore {

  /** геометрия для рисования */
  geometry: Array<AnnotationGeometry> = []
  /** координаты курсора мыши на канвасе*/
  cursorCoord: Array<number> = []
  /**названия ингредиентов  */
  labels: Array<string> = []
  /** трансформатор */
  helper: CanvasHelper = new CanvasHelper();

  /** current hightight */
  highlight?: HighlightItem = undefined

  name: string

  /*drawable image*/
  image: HTMLImageElement = new Image()

  /** bb for heatmap */
  bb?: Array<number> = undefined

  /**heatmap */
  heatmap?: Array<Array<number>> = undefined

  constructor(name: string) {
    //makeAutoObservable(this);    
    makeObservable(this, {
      geometry: observable,
      cursorCoord: observable,
      labels: observable,
      highlight: observable,
      helper: false,
      updateGeometry: action,
      setCursorCoord: action,
      setHighlight: action,
      clearHighlight: action,
    })

    this.name = name;
  }

  reset() {
    this.geometry = [];
    this.cursorCoord = []
    this.labels = []
    this.highlight = undefined
    this.image = new Image()
    this.bb = undefined
    this.heatmap = undefined
  }

  updateGeometry(geometry: Array<AnnotationGeometry>) {
    console.log("updateGeometry", geometry);
    this.geometry = geometry;
  }

  /** включение/отключение отображения геометрии для ингредиента */
  displayGeometry(key: string, display: boolean) {
    console.log("displayGeometry");
    let g = cloneDeep(this.geometry);
    for (let i = 0; i < g.length; i++) {
      if (g[i].key === key) {
        g[i].display = display;
      } else {
        // отключение показа полигонов для других ингредиентов
        if (g[i].polygons)
          g[i].display = false;
      }

    }
    this.updateGeometry(g);

  }

  /** включение/отключение отображения геометрии для frs */
  displayFRSGeometry(frs_name: string, display: boolean) {
    let g = cloneDeep(this.geometry);
    for (let i = 0; i < g.length; i++) {
      if (g[i].frs_name === frs_name)
        g[i].display = display;
    }
    this.updateGeometry(g);
  }

  /** формирования списка ингредиентов исходя из координат курсора мыши */
  setCursorCoord(coord: Array<number>) {
    this.cursorCoord = coord;

    let labels: Array<string> = [];

    for (let i = 0; i < this.geometry.length; i++) {
      let g = this.geometry[i];
      if (g.display) {
        let bInclude = false;
        if (g.polygons) {
          g.polygons.forEach(polygon => {
            let p = this.helper.transformPolygon(polygon);
            bInclude = bInclude || pointIsInPoly(coord, p);
          });
        } else if (g.bbox) {
          g.bbox.forEach(bbox => {
            let polygon = [
              bbox[0], bbox[1],
              bbox[0] + bbox[2], bbox[1],
              bbox[0] + bbox[2], bbox[1] + bbox[3],
              bbox[0], bbox[1] + bbox[3],
              bbox[0], bbox[1]
            ];
            polygon = this.helper.transformPolygon(polygon);
            bInclude = bInclude || pointIsInPoly(coord, polygon);
          });
        }

        if (bInclude) {
          labels.push(g.name);
        }
      }
    }
    //console.log("x:",coord[0],"y:",coord[1]);
    this.labels = labels;
  }

  initHelper(context: CanvasRenderingContext2D, img: HTMLImageElement) {
    this.helper.init(context, img);
  }

  /** имеет ли ингредиент геометрию? */
  hasGeometry(key: string) {
    if (key === undefined) {
      return false;
    }
    let g = this.geometry.find(el => el.key === key);
    if (g && g.bbox) //display only bbox geometry
      return true
    return false;
  }

  /** имеет ли frs геометрию? */
  hasFRSGeometry(frs_name: string) {
    for (let i = 0; i < this.geometry.length; i++) {
      let g = this.geometry[i];
      if (g.frs_name === frs_name)
        return true;
    }
    return false;
  }

  /** включенно ли отображение геометрии у ингредиента? */
  isGeometryDisplaying(key: string) {
    let g = this.geometry.find(el => el.key === key);
    if (g)
      return g.display;

    return false;
  }

  /** включенно ли отображение геометрии у frs? */
  isGeometryFRSDisplaying(frs_name: string) {
    for (let i = 0; i < this.geometry.length; i++) {
      let g = this.geometry[i];
      if (g.frs_name === frs_name && g.display)
        return true;
    }
    return false;
  }


  /** подсветить геометрию у ингридиента */
  setHighlight(key: string) {
    if (!this.highlight || this.highlight.key !== key) {
      this.highlight = {
        key: key
      };
      let g = this.geometry.find(el => el.key === key);
      if (g) {
        this.setHeatmap(g.heatmap);
      } else {
        this.setHeatmap();
      }
    }
  }

  /** снять подсветку */
  clearHighlight() {
    this.highlight = undefined;
  }


  /** подсвечивается ли геометрия у ингридиента */
  isHighlighted(key: string) {
    return (this.highlight && this.highlight.key === key);
  }

  /** загрузка геометрии для выбранного frs */
  loadGeometry(frs_name: string, results: Array<ModelResponse>) {
    console.log("loadGeometry:", frs_name);
    //let frsData = mainStore.mainViewStore.versionDetailsView.filter(frs => frs.frs_name === frs_name || frs_name === "All");
    let frsData = results.filter(frs => frs.frs_name === frs_name || frs_name === "All");

    let geometryData: Array<AnnotationGeometry> = frsData.flatMap(frs => frs.results.map((result, index) => ({
      "bbox": result.bbox,
      "polygons": [], //result.polygons,  //disable polygons
      "display": true,
      "name": result.name,
      //"index": index,
      "frs_name": frs.frs_name,
      "key": result.key!
    })
    ));


    let inner = frsData.flatMap(frs => frs.results.flatMap(result => ({
      "origin": result.bbox ? result.bbox[0] : undefined,
      "inner": result.inner as ModelResponse
    })));

    inner = inner.filter(el => el.inner !== undefined);
    let innerData: Array<AnnotationGeometry> = inner.flatMap(el => el.inner.results.map((result, index) => ({
      "bbox": result.bbox,
      "polygons": [], //shiftPolygon(el.origin, result.polygons), //disable polygons
      "heatmap": result.heatmap,
      "display": false,
      "name": result.name,
      //"index": index,
      "frs_name": el.inner.frs_name,
      "key": result.key!
    })
    ));

    geometryData.push(...innerData);

    geometryData = geometryData.filter(g => g.bbox || (g.polygons && g.polygons.length > 0) || g.heatmap);
    this.updateGeometry(geometryData);

  }

  setBB(bb: Array<number> | undefined = undefined) {
    console.log("set bb", bb)
    this.bb = bb;
  }

  setHeatmap(heatmap: Array<Array<number>> | undefined = undefined) {
    console.log("set heatmap", heatmap)
    this.heatmap = heatmap;
  }


}
