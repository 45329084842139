import React from 'react'
//import i18next from 'i18next';
import { withStyles } from '@material-ui/core/styles';
import { Button,Dialog,DialogTitle,DialogActions,DialogContent,DialogContentText,Theme } from '@material-ui/core';

const styles = (theme : Theme) => ({
});


export interface DialogWindowProps {
  /** is dialog opened */
   open : boolean
   /**  Close event handler */
   onClose : (buttonIndex : number) => void,
   /** dialog title */
   title? : string,
   /** dialog text */ 
   text? : string,  
   /** dialog buttons */
   buttons : Array<string>
}

interface DialogWindowState {
  open : boolean
}

/**
 *  Popup dialog with buttons
 */
class DialogWindow extends React.Component<DialogWindowProps,DialogWindowState> {
    constructor (props : DialogWindowProps) {
        super(props);
        this.state = {
            open : this.props.open
        };
    }

    
    componentDidUpdate() {
        if (this.props.open !== this.state.open) {
            this.setState({open : this.props.open});        
        }   
    }

    render() {
        return (
            <Dialog
            open={this.state.open}            
            //onClose={()=>{this.props.onClose(false)}}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{this.props.title || ""}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {this.props.text}
              </DialogContentText>
            </DialogContent>
            <DialogActions>      
            {this.props.buttons.map((item, index) => {        
              return (
              <Button key={index} onClick={()=> {this.props.onClose(index)}} color="primary">
                {item}
              </Button>
              )
            })}
            </DialogActions>
          </Dialog>
        )
    }
}

export default withStyles(styles)(DialogWindow);