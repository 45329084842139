import React,{useState} from 'react';
import { withStyles,createStyles } from '@material-ui/core/styles';
import { Button,Dialog,DialogContent,Grid,Theme,DialogTitle,Checkbox,FormControlLabel } from '@material-ui/core';
import i18next from 'i18next';
import {FRSFilterOption} from '../dto/fpsDTO';

const styles = (theme : Theme) => createStyles({  
  button: {
    maxWidth : 200,
    height : 40,
    marginLeft : 16
  },
  popup : {
    marginRight : 100
  },
  textNotes : {
    width:"90%",
    marginLeft:"5%",
    marginRight:"5%"
  }
})


 export interface FRSSelectProps    {
  /** is popup opened */
  open: boolean,
  /** all frs  */
  frs: Array<FRSFilterOption>,
  /** marked frs  */
 // frsFiltered: Array<string>,
  /** Close event handler */
  onClose : (bSave : boolean,frs : Array<FRSFilterOption>) => void, 

  classes : any
}


/** Popup для выбора frs */
const FRSSelect = (props : FRSSelectProps) => {
  
  let activeFRS = props.frs.filter(el=>el.active === true).map(el=>el.name);

  const [frsFiltered,setFrs] = useState<Array<string>>(activeFRS);

  const closeDialog = (bSave : boolean) => {      
      let newFRS = props.frs.map(el=> {
        return {
          "name" : el.name,
          "active" : frsFiltered.includes(el.name)
        }
    });
      props.onClose(bSave,newFRS);      
  }

  const handleFRSMark = (el : React.ChangeEvent<HTMLInputElement> ,frs : string) => {
    if (frsFiltered.includes(frs)) {
      setFrs(frsFiltered.filter(el=>el !== frs));
    } else {
      setFrs(frsFiltered.concat(frs));
    }
  }

  const classes = { ...props.classes };

  return (    
    <Dialog 
       open = {props.open}
       classes = {{
        paper : classes.popup
      }}
       >
         <DialogContent style={{width : "330px"}}>
         <DialogTitle style={{textAlign : "center"}}>
          {i18next.t('frs_choose')}
         </DialogTitle>
         <Grid container direction="column">
         {props.frs.map(el=>{
            return (
              <Grid item key={el.name}>
              <FormControlLabel
                control={<Checkbox checked={frsFiltered.includes(el.name)} onChange={(e)=>handleFRSMark(e,el.name)} name={el.name} />}              
                label={el.name}
              />
              </Grid>
            )
         })}
         </Grid>
        <Grid container direction="row" justify="space-around" style={{paddingTop : 8}}>
         <Button variant="contained" className={classes.button} onClick={(e)=>closeDialog(true)}> {i18next.t('OK')}  </Button>
         <Button variant="contained" color="secondary" className={classes.button} onClick={(e)=>closeDialog(false)}> {i18next.t('Close')}  </Button>
         </Grid>     
         </DialogContent>         
       </Dialog> 
  )
}

 export default withStyles(styles)(FRSSelect);
 




