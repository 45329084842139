import { makeAutoObservable } from "mobx"


export default class ImageUploadStore {

  loading: boolean = false
  error: boolean = false
  errorMsg: string = ""
  image?: string = undefined
  showWarning: boolean = false
  fileUploaded: string = ""


  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.loading = false
    this.error = false
    this.errorMsg = ""
    this.image = undefined
    this.showWarning = false
    this.fileUploaded = ""
  }

  startImageLoading() {
    this.loading = true;
  }

  onImageLoaded(image: string) {
    this.image = image;
  }

  onImageUploaded(file: string) {
    this.fileUploaded = file;
  }

  onRequestSuccess() {
    this.loading = false;
    this.error = false;
    this.errorMsg = "";
    this.showWarning = false;
  }

  onRequestFail(errorMsg: string) {
    this.loading = false;
    this.error = true;
    this.errorMsg = errorMsg;
    this.showWarning = false;
  }

  onImageExists(errorMsg: string) {
    this.loading = false;
    this.error = true;
    this.errorMsg = errorMsg;
    this.showWarning = true;
  }

  closeDialog() {
    this.error = false;
    this.errorMsg = "";
    this.showWarning = false;
  }

  /*reset() {
    this.image = undefined;
    this.fileUploaded = "";
  }*/
}
