import React,{useState} from 'react';
import { withStyles,createStyles } from '@material-ui/core/styles';
import { Button,TextField,Dialog,DialogContent,Grid,Theme,DialogTitle } from '@material-ui/core';
import i18next from 'i18next';

const styles = (theme : Theme) => createStyles({  
  button: {
    maxWidth : 200,
    height : 40,
    marginLeft : 16
  },
  popup : {
    marginRight : 100
  },
  textNotes : {
    width:"90%",
    marginLeft:"5%",
    marginRight:"5%"
  }
})


 export interface LoginViewProps    {
  /** открыт ли popup */
  open: boolean,
  /** callback для логина/регистрации */
  onSignIn : (login : string,password : string,isSignIn : boolean) => void, 

  /** ошибка логина */
  error :  string,
  
  classes : any
}


/** Popup для логина/регистрации */
const LoginView = (props : LoginViewProps) => {
 
 /** логин */ 
 const [login,setLogin] = useState<string>();
 /** пароль */
 const [password,setPassword] = useState<string>();

 /** ошбика ввода логина */
 const [loginError,setLoginError] = useState<string>("");
 /** ошибка ввода пароля */
 const [passwordError,setPasswordError] = useState<string>("");

 /** режим : логин (true) или регистрация (false) */
 const [signInMode,setSignInMode] = useState<boolean>(true);

 const closeDialog = () => {   
    let ok = true;
    
    if (!login || login.trim().length  === 0) {
      setLoginError(i18next.t('login_error'));
      ok = false;
    } else {
      setLoginError("");
    }
    
    if (!password || password.trim().length  === 0) {
      setPasswordError(i18next.t('password_error'));
      ok  = false;
    } else {
      setPasswordError("");
    }

    if (ok) {            
      props.onSignIn(login!,password!,signInMode);      
    }
  }
  
  const classes = { ...props.classes };

  return (    
    <Dialog 
       open = {props.open}
       classes = {{
        paper : classes.popup
      }}
       >
         <DialogContent style={{width : "250px",height : "320px"}}>
         <DialogTitle style={{textAlign : "center"}}>
          {signInMode ? i18next.t('signin') : i18next.t('signup')}
         </DialogTitle>
         <Grid container direction="column"  spacing={2} >
          <Grid item style = {{textAlign : "center"}}>
          <TextField
            id="standard-name"
            label={i18next.t('login')}
            error = {loginError !== ""}    
            autoFocus = {true}
            onChange={(e)=>setLogin(e.target.value)}    
            helperText = {loginError}
          />
          </Grid>
          <Grid item style = {{textAlign : "center"}}>
          <TextField
            id="standard-password"
            label={i18next.t('password')}
            type="password"
            error = {passwordError !== ""}    
            onChange={(e)=>setPassword(e.target.value)}           
            helperText = {passwordError}
          />        
          </Grid>
          <Grid item>
          <Grid item style={{color : "red",textAlign : "center"}}>
            {i18next.t(props.error)}
          </Grid>
        <Grid container direction="row" justify="space-around" style={{paddingTop : 12}}>
         <Button variant="contained"  color="primary" className={classes.button} onClick={(e)=>closeDialog()}> {i18next.t('OK')}  </Button>
         {/*<Button variant="contained" color="secondary" className={classes.button} onClick={(e)=>setSignInMode(!signInMode)}> { signInMode ? i18next.t('signup') : i18next.t('signin') }  </Button>*/ }
        </Grid>     
        </Grid>
       </Grid>
         </DialogContent>         
       </Dialog> 
  )
}

 export default withStyles(styles)(LoginView);
 




