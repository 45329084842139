import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableRow, TableCell, Theme, Switch, FormControlLabel, TableHead, Checkbox, Typography, Grid, Button } from '@material-ui/core';
import { observer } from "mobx-react"
import { ModelResponseDTO, RecognitionResultDTO, GetHeatmapDTO } from './../dto/fpsDTO'
import mainStore from "./../stores/mainStore"
import i18next from 'i18next';
import foodRecognitionAPI from './../recognitionAPI'
import { ModelResponse, RecognitionResult } from "./../stores/mainViewStore"
import GeometryStore from './../stores/geometryStore'

const styles = (theme: Theme) => createStyles(({
  frsCell: {
    borderRightWidth: '2px',
    borderRightStyle: 'solid',
    borderRightColor: "initial"
  },
  table: {
    //tableLayout: "fixed"
  },
  description: {
    fontWeight: "bold",
    //textAlign: "center"
  },
  checkbox: {
    padding: 0
  },
  img: {
    width: 24,
    height: 24,
    verticalAlign: "middle",
    marginLeft: 8,
    marginRight: 8,
    cursor: "pointer"
  },
}))


export interface RecognitionResultsTableProps {
  /** top candidate */
  topCandidate?: string
  /** recognition results */
  records: Array<ModelResponse>
  /** frs filter */
  frsFilter?: string
  /** показывать заголовок таблицы */
  showHeaders?: boolean
  /** округление поля confidence */
  confidenceRounding?: number
  /** рендеринг под мобильную версию */
  mobile: boolean
  /** загружать контролы для frs?*/
  loadControls: boolean
  /** */
  store: GeometryStore
  /** */
  classes: any
}

//const geometryStore = mainStore.geometryStore

const frsDescription = (record: ModelResponse) => {
  let s = record.frs_name;
  if (record.model_version)
    s += ` version:${record.model_version}`;
  return s;
}

const rowStyle = (drawBold: boolean, backgroundColor: string = "white"): React.CSSProperties => {
  return {
    height: '50px',
    width: '100%',
    borderTopWidth: drawBold ? '2px' : '1px',
    borderTopStyle: drawBold ? 'solid' : 'none',
    borderTopColor: "initial",
    overflow: 'hidden',
    backgroundColor: backgroundColor
    //background : color
  };
}

const rowSelect = (key: string, store: GeometryStore) => (e: React.MouseEvent<HTMLTableRowElement>) => {
  store.setHighlight(key);
}

const showGeometry = (key: string, store: GeometryStore) => (e: React.ChangeEvent<HTMLElement>, checked: boolean) => {
  store.displayGeometry(key, checked)
}

const handleShowBB = (frs_name: string, store: GeometryStore) => (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  store.displayFRSGeometry(frs_name, checked)
}

const displayName = (row: RecognitionResultDTO) => {
  if (row.presents !== undefined) {
    if (row.presents === true)
      return `${row.name} (TRUE)`;
    else
      return `${row.name} (FALSE)`;
  }

  return row.name
}



interface TableRecordProps {
  /** top candidate */
  topCandidate?: string
  record: ModelResponse
  /** округление поля confidence */
  confidenceRounding?: number
  /** */
  store: GeometryStore
  //index: number
  classes: any
}

/** отображение записи в таблице для телефона */
const TableRecord = observer(
  class TableRecord extends React.Component<TableRecordProps> {
    render() {
      //console.log("tablerecord render");
      let classes = { ...this.props.classes };
      let hasError = this.props.record.error && typeof (this.props.record.error) !== "object";
      if (this.props.record.results.length == 0)
        return (
          <Table size="small" className={classes.table}>
            <colgroup>
              <col style={{ width: '17%' }} />
              <col style={{ width: '50%' }} />
            </colgroup>
            <TableBody>
              <TableRow style={rowStyle(true, "white")} >
                <TableCell />
                <TableCell className={classes.description}>
                  {frsDescription(this.props.record)}
                </TableCell>
              </TableRow>
              <TableRow style={rowStyle(false)}>
                <TableCell />
                <TableCell style={{ color: "red" }}>{this.props.record.error}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )


      return (
        <>
          <Table size="small" className={classes.table}>
            <colgroup>
              <col style={{ width: '17%' }} />
              <col style={{ width: '50%' }} />
            </colgroup>
            <TableBody>
              <TableRow style={rowStyle(true, "white")} >
                <TableCell>
                  {this.props.store.hasFRSGeometry(this.props.record.frs_name) &&
                    <Checkbox
                      className={classes.checkbox}
                      //size="small"
                      checked={this.props.store.isGeometryFRSDisplaying(this.props.record.frs_name)}
                      onChange={handleShowBB(this.props.record.frs_name, this.props.store)} />
                  }
                </TableCell>
                <TableCell className={classes.description}>
                  {frsDescription(this.props.record)}
                </TableCell>
              </TableRow>
              {this.props.record.results.length == 0 &&
                <TableRow style={rowStyle(false)}>
                  <TableCell />
                  <TableCell />
                </TableRow>
              }
            </TableBody>
          </Table>

          <Table size="small" className={classes.table}>
            <colgroup>
              <col style={{ width: '17%' }} />
              <col style={{ width: '3%' }} />
              <col style={{ width: '40%' }} />
              <col style={{ width: '25%' }} />
            </colgroup>
            <TableBody>
              {
                this.props.record.results.map((row, index) => {
                  //let textColor = (row.name === this.props.topCandidate) ? "blue" : "black";
                  let textColor = (row.presents === undefined && index === 0 || row.presents === true) ? "blue" : "black";
                  let backgroundColor = this.props.store.isHighlighted(row.key!) ? "whitesmoke" : "white";
                  return (
                    <TableRow key={index} onClick={rowSelect(row.key!, this.props.store)} style={rowStyle(false, backgroundColor)}>
                      <TableCell >
                        {this.props.store.hasFRSGeometry(this.props.record.frs_name) && this.props.record.results.length > 1 &&
                          <Checkbox
                            className={classes.checkbox}
                            //size="small"
                            checked={this.props.store.isGeometryDisplaying(row.key!)}
                            onChange={showGeometry(row.key!, this.props.store)} />
                        }
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ color: textColor }}>
                        {displayName(row)}{/*row.name*/}
                      </TableCell>
                      <TableCell style={{ color: textColor }}>
                        {this.props.confidenceRounding ? Number(row.confidency).toFixed(this.props.confidenceRounding) : row.confidency}
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </>
      )
    }
  }
)

enum InnerIcon {
  None = 1,
  Down = 2,
  Up = 3,
  UpDown = 4
}

interface ResultRowProps {
  key: string
  index: number
  record: ModelResponse
  /** округление поля confidence */
  confidenceRounding?: number
  /** загружать контролы для frs?*/
  loadControls: boolean
  drawSeparatorBold: boolean
  row: RecognitionResult
  innerIcon: InnerIcon
  parentKey?: string
  classes: any
  /** */
  store: GeometryStore
}

interface FrsCellProps {
  index: number
  record: ModelResponse
  /** загружать контролы для frs?*/
  loadControls: boolean
  /** */
  store: GeometryStore
  classes: any
}

interface ClassNameCellProps {
  row: RecognitionResult
  textColor: string
}


interface GeometryCellProps {
  row_key: string
  /** */
  store: GeometryStore
  classes: any
}


const FrsCell = observer(
  class FrsCell extends React.Component<FrsCellProps> {

    loadHeatmap(frsName: string) {
      mainStore.mainViewStore.setPopupLoading(true);
      foodRecognitionAPI.getHeatmap(mainStore.mainViewStore.image!, frsName).then((result: GetHeatmapDTO) => {
        mainStore.mainViewStore.updatePolygons(frsName, result.data.results);
        let obj = [{
          frs_name: frsName,
          results: result.data.results
        }]

        this.props.store.loadGeometry(frsName, obj as Array<ModelResponseDTO>);
        mainStore.mainViewStore.setPopupLoading(false);
      })
    }

    render() {
      let classes = { ...this.props.classes };
      let index = this.props.index;
      let record = this.props.record;
      return (
        <TableCell className={classes.frsCell}>
          {index === 0 &&
            <div>
              {frsDescription(record)}
              {this.props.store.hasFRSGeometry(record.frs_name) &&
                <Checkbox
                  checked={this.props.store.isGeometryFRSDisplaying(record.frs_name)}
                  onChange={handleShowBB(record.frs_name, this.props.store)}></Checkbox>
              }
            </div>
          }
          {this.props.loadControls && index === 1 && mainStore.mainViewStore.hasHeatmap(record.frs_name) &&
            <Button variant="contained" color="secondary" onClick={(e) => this.loadHeatmap(record.frs_name)}>
              {i18next.t('load_heatmap')}
            </Button>
          }

        </TableCell>
      )
    }
  }
)

const ClassNameCell = observer(
  class ClassNameCell extends React.Component<ClassNameCellProps> {
    render() {
      return (
        <TableCell style={{ color: this.props.textColor }}> {displayName(this.props.row)}</TableCell>
      )
    }
  }
)

const GeometryCell = observer(
  class GeometryCell extends React.Component<GeometryCellProps> {

    render() {
      let geometryStore = this.props.store;
      return (
        <TableCell>
          {
            geometryStore.hasGeometry(this.props.row_key) &&
            <Switch
              checked={geometryStore.isGeometryDisplaying(this.props.row_key)}
              onChange={showGeometry(this.props.row_key, this.props.store)}
            />
          }
        </TableCell>
      )
    }
  }
)

const ResultRow = observer(
  class ResultRow extends React.Component<ResultRowProps> {

    showInner(key: string) {
      mainStore.mainViewStore.updateShowInner(key, this.props.store);
    }

    render() {
      let classes = { ...this.props.classes };
      let index = this.props.index;
      let record = this.props.record;
      let row = this.props.row;
      let geometryStore = this.props.store;

      let textColor = (row.presents === undefined && index === 0 || row.presents === true) ? "blue" : "black";
      let backgroundColor = geometryStore.isHighlighted(row.key!) ? "whitesmoke" : "white";

      return (
        <TableRow /*key={this.props.key}*/ style={rowStyle(this.props.drawSeparatorBold, backgroundColor)} onClick={rowSelect(row.key!, this.props.store)}>
          <FrsCell classes={classes} index={index} record={record} loadControls={this.props.loadControls} store={geometryStore} />
          <TableCell >{index + 1}</TableCell>
          <ClassNameCell row={row} textColor={textColor} />
          {/*<TableCell style={{ color: textColor }}>{displayName(row)}</TableCell>*/}
          <TableCell style={{ color: textColor }}>
            {this.props.confidenceRounding ? Number(row.confidency).toFixed(this.props.confidenceRounding) : row.confidency}
          </TableCell>
          <GeometryCell classes={classes} row_key={row.key!} store={geometryStore} />
          <TableCell >
            {(this.props.innerIcon === InnerIcon.Down) &&
              <img src={process.env.PUBLIC_URL + '/images/down-arrows.png'} alt="/images/down-arrows.png" className={classes.img} onClick={(e) => this.showInner(row.key!)} />
            }
            {(this.props.innerIcon === InnerIcon.Up) && (index === 0) &&
              <img src={process.env.PUBLIC_URL + '/images/up-arrows.png'} alt="/images/up-arrows.png" className={classes.img} onClick={(e) => this.showInner(this.props.parentKey!)} />
            }
          </TableCell>
        </TableRow >
      )
    }
  }
)


/**
 *  Table control with recognition results
 */
const RecognitionResultsTable = observer(
  class RecognitionResultsTable extends React.Component<RecognitionResultsTableProps> {

    componentDidMount() {
      /*if (this.props.records && this.props.records[0])
        geometryStore.setHighlight(0, this.props.records[0].frs_name);*/
    }

    renderMobile() {
      let classes = { ...this.props.classes };
      return (
        this.props.records.map((record, n) => {
          return (
            <TableRecord key={n} topCandidate={this.props.topCandidate} record={record} confidenceRounding={this.props.confidenceRounding} classes={this.props.classes} store={this.props.store} />
          )
        })
      )
    }

    render() {
      if (this.props.mobile)
        return this.renderMobile();
      let classes = { ...this.props.classes };
      let drawSeparatorBold = false;
      return (
        <Table size="small" className={classes.table}>
          <colgroup>
            <col style={{ width: '30%' }} />
            <col style={{ width: '2%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
          {this.props.showHeaders &&
            <TableHead>
              <TableRow>
                <TableCell> {i18next.t('frs')} </TableCell>
                <TableCell />
                <TableCell />
                <TableCell> {i18next.t('confidence')} </TableCell>
                <TableCell>{i18next.t('geometry_on_off')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {
              this.props.records.map((record, n) => {
                if (!this.props.frsFilter || this.props.frsFilter === record.frs_name) {
                  if (record.results.length > 0) {
                    return record.results.map((row, index) => {
                      drawSeparatorBold = (index === 0 && n > 0 && !this.props.frsFilter);
                      //let textColor = (row.presents === undefined && index === 0 || row.presents === true) ? "blue" : "black";
                      //let backgroundColor = geometryStore.isHighlighted(index, record.frs_name) ? "whitesmoke" : "white";
                      if (!row.showInner) {
                        return (
                          <ResultRow classes={this.props.classes} key={`${n}:${index}`} index={index} loadControls={this.props.loadControls} record={record} drawSeparatorBold={drawSeparatorBold} row={row} innerIcon={row.inner ? InnerIcon.Down : InnerIcon.None} store={this.props.store} />
                        )
                      }
                      else {
                        return row.inner!.results.map((inner, inner_idx) => {
                          return (
                            <ResultRow classes={this.props.classes} key={`${n}:${index}:${inner_idx}`} index={inner_idx} loadControls={this.props.loadControls} record={record} drawSeparatorBold={drawSeparatorBold} row={inner} innerIcon={InnerIcon.Up} parentKey={row.key} store={this.props.store} />
                          )
                        })
                      }
                    })
                  }
                  else {
                    drawSeparatorBold = (n > 0);
                    let hasError = record.error && typeof (record.error) !== "object";
                    return (
                      <TableRow key={`${n}`} style={rowStyle(drawSeparatorBold)}  >
                        <TableCell className={classes.frsCell}> {frsDescription(record)} </TableCell>
                        {hasError &&
                          <>
                            <TableCell />
                            <TableCell style={{ color: "red" }}>{record.error}</TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                          </>
                        }
                      </TableRow>
                    )
                  }
                } else
                  return (null);
              })
            }
          </TableBody>
        </Table >
      )
    }
  })

export default withStyles(styles)(RecognitionResultsTable);




