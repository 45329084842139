import React,{useState} from 'react';
import { withStyles,createStyles } from '@material-ui/core/styles';
import { Button,TextField,Dialog,DialogContent,Grid,Theme } from '@material-ui/core';
import i18next from 'i18next';

const styles = (theme : Theme) => createStyles({  
  button: {
    maxWidth : 200,
    height : 40,
    marginLeft : 16
  },
  popup : {
    marginRight : 100
  },
  textNotes : {
    width:"90%",
    marginLeft:"5%",
    marginRight:"5%"
  }
})


 export interface NotesEditorProps    {
  /** is popup opened */
  open: boolean,
  /** notes */
  notes?: string,
  /** Close event handler */
  onClose : (bSave : boolean,notes? : string) => void, 

  classes : any
}


/** Notes editor popup */
const NotesEditor = (props : NotesEditorProps) => {
  
  const [notes,setNotes] = useState<string | null>(null);

  const closeDialog = (bSave : boolean) => {
      //component still alive when closed,so workaround need
      let _notes = (notes !== null) ? notes : props.notes;
      props.onClose(bSave,_notes);
      setNotes(null);      
  }

  const handleNotes = () => (event : React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  }

  const classes = { ...props.classes };

  return (    
    <Dialog 
       open = {props.open}
      // onClose = {this.handleClose}
       classes = {{
        paper : classes.popup
      }}
       >
         <DialogContent style={{width : "330px"}}>
         <TextField
                              defaultValue={props.notes}
                              onChange={handleNotes()}
                              margin="normal"
                              variant="outlined" 
                              multiline = {true}   
                              className = {classes.textNotes}                               
                          />      
        <Grid container direction="row" justify="space-around">
         <Button variant="contained" className={classes.button} onClick={(e)=>closeDialog(true)}> {i18next.t('OK')}  </Button>
         <Button variant="contained" color="secondary" className={classes.button} onClick={(e)=>closeDialog(false)}> {i18next.t('Close')}  </Button>
         </Grid>     
         </DialogContent>         
       </Dialog> 
  )
}

 export default withStyles(styles)(NotesEditor);
 




