import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button, TextField, Dialog, DialogContent, Grid, Theme, DialogTitle } from '@material-ui/core';
import i18next from 'i18next';
import FoodImage from './foodImage';
import RecognitionResultsTable from './resultsTable';
import { useLocation, useParams } from 'react-router-dom';
import foodRecognitionAPI from '../recognitionAPI';
import UIState from './../stores/uiState';
import { toJS } from "mobx"
import mainStore from "./../stores/mainStore"
import { ModelResponseDTO } from 'dto/fpsDTO';
import { observer } from 'mobx-react';
const queryString = require('query-string');


const styles = (theme: Theme) => createStyles({
  root: {
    overflowX: "hidden",
  },
  table: {
    width: "95vw"
  }
})


export interface DetailsViewProps {
  classes: any
  location: {
    search: string
  }
}

export interface IDetailsRouteParams {
  id: string;
}

/** View с фотографией и результатами для  мобильной версии */
const DetailsView = observer((props: DetailsViewProps) => {

  const store = mainStore.mainViewStore
  const geometryStore = mainStore.geometryStore

  const [showImage, setShowImage] = useState<boolean>(false);

  useEffect(() => {
    const qs = queryString.parse(props.location.search);
    UIState.setToken(qs.token);
  }, [])

  let { id } = useParams<IDetailsRouteParams>();

  useEffect(() => {
    foodRecognitionAPI.lastResults(id).then(data => {
      if (data.success) {
        geometryStore.loadGeometry("All", data.data);
        store.setLastResults(data.data);
        setShowImage(true);
      }
    });

  }, [id])


  const imageLoaded = () => {
    console.log("image loaded...");
    geometryStore.loadGeometry("All", store.lastResults);
  }

  const classes = { ...props.classes };

  return (
    <div className={classes.root}>
      {showImage &&
        <FoodImage image={id} download={true} width={window.screen.width} height={450} useCanvas={true} shared={false} geometry={geometryStore.geometry} highlight={geometryStore.highlight} imageLoaded={imageLoaded} store={geometryStore} />
      }
      <div className={classes.table}>
        <RecognitionResultsTable records={store.lastResults} topCandidate={store.topCandidate} showHeaders={true} confidenceRounding={3} mobile={true} loadControls={false} store={geometryStore} />
      </div>
    </div>
  )
})

export default withStyles(styles)(DetailsView);





