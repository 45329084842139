import React, { useEffect, useState } from 'react';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, Grid, Theme, MenuItem, TextField, CircularProgress, Checkbox, FormControlLabel, Slider, Select, FormControl, InputLabel } from '@material-ui/core';
import mainStore from "./../stores/mainStore"
import i18next from 'i18next';
import { observer } from "mobx-react";
import FoodImage from './foodImage';
import HeatmapImage from './heatmapImage';
import VersionsDetailTable from './versionsDetailTable'
import RecognitionResultsTable from './resultsTable';
import foodRecognitionAPI from './../recognitionAPI'
import InputDialog from './inputDialog'
import ImagePopup from './imagePopup'
import GeometryStore from './../stores/geometryStore'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      minWidth: 160,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export enum HeatmapStyle {
  Fill = "0",
  Dots = "1",
  Sun = "2"
}


export interface HeatmapStyleSelectProps {
  value: HeatmapStyle,
  setValue: (style: HeatmapStyle) => void
}


export function HeatmapStyleSelect(props: HeatmapStyleSelectProps) {
  const classes = useStyles();
  const [heatmapStyle, setHeatmapStyle] = React.useState(props.value);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let value = event.target.value as HeatmapStyle;
    setHeatmapStyle(value);
    props.setValue(value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Heatmap style</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={heatmapStyle}
        onChange={handleChange}
      >
        <MenuItem value={HeatmapStyle.Fill}>Fill</MenuItem>
        <MenuItem value={HeatmapStyle.Dots}>Dots</MenuItem>
        <MenuItem value={HeatmapStyle.Sun}>Sun</MenuItem>
      </Select>
    </FormControl>
  )
}

const styles = createStyles((theme: Theme) => ({
  popup: {
    marginRight: 100
  },
  comboContainer: {
    width: 250
  },
  img: {
    width: 24,
    height: 24,
    verticalAlign: "middle",
    marginLeft: 8,
    marginRight: 8,
    cursor: "pointer"
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }
}))

export interface PopupViewProps {
  /** is dialog opened */
  open: boolean,
  /** download from shared? */
  downloadShared: boolean,
  /** array with versions */
  //versions: Array<number>,
  /** geometry store */
  store: GeometryStore
  classes: any
}


interface FRSSelectComboProps {
  /** список frs */
  frsList: Array<string>,
  /** callback to call when frs is changed */
  frsChanged: (frs: string) => void
}

interface IngredientLabelsProps {
  store: GeometryStore
}

const IngredientLabels = observer((props: IngredientLabelsProps) => {
  const geometryStore = props.store

  return (
    <Grid container direction="column" spacing={2}>
      {geometryStore.labels.map((label, i) => {
        return (
          <Grid key={i} item>
            {label}
          </Grid>
        )
      })}
    </Grid>
  )
})

const FRSSelectCombo = observer((props: FRSSelectComboProps) => {

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let frsName = event.target.value as string;
    props.frsChanged(frsName);
  };

  const store = mainStore.mainViewStore

  return (
    <TextField
      //className = {classes.combo}
      label={i18next.t('show_frs')}
      style={{
        minWidth: 150
      }}
      id="select"
      value={store.currentFRS}
      select
      onChange={handleChange}
    >
      {props.frsList.map((name, i) => {
        return (
          <MenuItem key={i} value={name}>{name}</MenuItem>
        );
      })}
    </TextField>
  )
})


/**
 * Popup показывающий результаты распознавания изображения для выбранной глобальной версии 
**/
const PopupView = observer((props: PopupViewProps) => {


  const store = mainStore.mainViewStore
  const geometryStore = props.store

  const [openDownloadDialog, setOpenDownloadDialog] = useState<boolean>(false);
  //const [heatmapCBChecked, setheatmapCBChecked] = useState<boolean>(false);
  const [heatmapStyle, setHeatmapStyle] = React.useState(HeatmapStyle.Fill);
  const [sliderValue, setSliderValue] = useState<number>(0);

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 25,
      label: '25',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 75,
      label: '75',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  const closeDialog = () => {
    store.closePopup();
    geometryStore.setBB();
    geometryStore.setHeatmap();
    store.clearShowInner();
  }

  const frsChanged = (frs: string) => {
    store.setCurrentFRS(frs);
    geometryStore.loadGeometry(frs, store.versionDetailsView);
  }

  const imageLoaded = () => {
    console.log("image loaded...");
    geometryStore.loadGeometry(store.currentFRS, store.versionDetailsView);
  }

  const versionSelected = (version: number, topCandidate: string, topFRS: string) => {
    console.log("version selected...");
    store.switchVersion(version, topCandidate, topFRS);
    geometryStore.loadGeometry(store.currentFRS, store.versionDetailsView);
  }

  const downloadImage = (fileName: string, cancel: boolean) => {
    if (!cancel) {
      console.log("download", store.image)
      let uri = foodRecognitionAPI.download(store.image!, props.downloadShared, fileName);
      var link = document.createElement("a");
      //link.setAttribute('download', 'zzz');
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    setOpenDownloadDialog(false);
  }



  const showImagePopup = () => {
    store.handleImagePopup(true);
  }

  const closeImagePopup = () => {
    store.handleImagePopup(false);
    //geometryStore.loadGeometry(store.currentFRS, store.versionDetailsView);
  }

  const handleHeatmapStyle = (style: HeatmapStyle) => {
    setHeatmapStyle(style);
    //setheatmapCBChecked(!heatmapCBChecked);
  }

  const sliderChange = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
    setSliderValue(newValue as number);
  }


  let classes = { ...props.classes };
  let showGeometry = store.openVersionDetails;

  return (
    <Dialog
      open={props.open}
      className={classes.popup}
      fullWidth={true}
      maxWidth={"lg"}
      fullScreen
    >
      <DialogContent >
        <React.Fragment>
          <Grid container direction="column" spacing={1}>
            <Grid item style={{ maxWidth: 1200 }}>
              <Grid container direction="row" spacing={4}>
                <Grid item>
                  <Button variant="contained" className={classes.button} onClick={(e) => closeDialog()}> {i18next.t('close')}  </Button>
                </Grid>
                <Grid item>
                  <img src={process.env.PUBLIC_URL + '/images/left-arrow.png'} alt="/images/left-arrow.png" style={{ opacity: store.hasPreviousImage() ? 1 : 0.5 }} className={classes.img} onClick={(e) => store.previousImage()} />
                </Grid>
                <Grid item>
                  {i18next.t('change_image')}
                </Grid>
                <Grid item>
                  <img src={process.env.PUBLIC_URL + '/images/right-arrow.png'} alt="/images/right-arrow.png" style={{ opacity: store.hasNextImage() ? 1 : 0.5 }} className={classes.img} onClick={(e) => store.nextImage()} />
                </Grid>
                <Grid item>
                  <img src={process.env.PUBLIC_URL + '/images/download.png'} alt="/images/download.png" className={classes.img} onClick={(e) => setOpenDownloadDialog(true)} />
                </Grid>
                <Grid item>
                  <img src={process.env.PUBLIC_URL + '/images/magnify.png'} alt="/images/magnify.png" className={classes.img} onClick={(e) => showImagePopup()} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={4}>
                <Grid item>
                  <FoodImage image={store.image!} alias={store.topCandidate} width={600} height={450} useCanvas={true} shared={props.downloadShared} geometry={showGeometry ? geometryStore.geometry : undefined} highlight={showGeometry ? geometryStore.highlight : undefined} imageLoaded={showGeometry ? imageLoaded : undefined} store={geometryStore} />
                </Grid>
                {store.openVersionDetails &&
                  <Grid item>
                    <Grid container direction="column" spacing={4}>
                      <Grid item className={classes.comboContainer}>
                        <FRSSelectCombo frsList={store.frsList} frsChanged={frsChanged} />
                      </Grid>
                      <Grid item>
                        <IngredientLabels store={geometryStore} />
                      </Grid>
                      <Grid item>
                        {/*<FormControlLabel control={<Checkbox checked={heatmapCBChecked} />} label="heatmap style" onChange={handleHeatmapCB} />*/}
                        <HeatmapStyleSelect value={HeatmapStyle.Fill} setValue={handleHeatmapStyle} />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Slider defaultValue={0} step={5} marks={marks} min={0} max={100} onChange={sliderChange} valueLabelDisplay={'auto'} />
                    </Grid>
                  </Grid>
                }
                <Grid item>
                  <HeatmapImage width={600} height={600} store={geometryStore} geometry={geometryStore.geometry} heatmapStyle={heatmapStyle} threshold={sliderValue} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Button variant="contained" color="secondary" onClick={(e) => store.switchAll()} disabled={store.openHistoryDetails}>{i18next.t('show_all_versions')} </Button>
                </Grid>
                <Grid item>
                  {store.openVersionDetails &&
                    <div style={{ textAlign: "center", marginLeft: 200 }}>
                      <img src={process.env.PUBLIC_URL + '/images/left-arrow.png'} alt="/images/left-arrow.png" style={{ opacity: store.hasPreviousVersion() ? 1 : 0.5 }} className={classes.img} onClick={(e) => store.previousVersion(geometryStore)} />
                      {i18next.t('version_n', { version: store.version })}
                      <img src={process.env.PUBLIC_URL + '/images/right-arrow.png'} alt="/images/right-arrow.png" style={{ opacity: store.hasNextVersion() ? 1 : 0.5 }} className={classes.img} onClick={(e) => store.nextVersion(geometryStore)} />
                    </div>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {store.openVersionDetails &&
                <RecognitionResultsTable records={store.versionDetailsView} topCandidate={store.topCandidate} frsFilter={store.currentFRS === "All" ? "" : store.currentFRS} showHeaders={true} mobile={false} loadControls={true} store={geometryStore} />
              }
              {store.openHistoryDetails &&
                <VersionsDetailTable record={store.historyDetailsView} versionSelected={versionSelected} />
              }
            </Grid>
          </Grid>
          {openDownloadDialog &&
            <InputDialog open={true} title={i18next.t('set_filename')} onClose={(fileName, cancel) => { downloadImage(fileName, cancel) }} inputText={store.imageName} />
          }
          {store.showPopupLoading &&
            <CircularProgress className={classes.progress} />
          }
          {store.imagePopupOpened &&
            <ImagePopup open={true} onClose={closeImagePopup} store={mainStore.imagePopupStore} />
          }
        </React.Fragment>
      </DialogContent>
    </Dialog>
  );
})

export default withStyles(styles)(PopupView);





