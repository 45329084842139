/*import 'core-js/stable'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';*/
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MainView from './components/mainView';
//import i18next from 'i18next';
import UIState from './stores/uiState';
//import DevTools from 'mobx-react-devtools';
import DetailsView from './components/detailsView'

UIState.init();


const Application = () => (


  < BrowserRouter >
    <Switch>
      <Route exact path='/' render={(props) => (
        <MainView {...props} />
      )} />
      <Route path='/details/:id' render={(props) => (
        <>
          <DetailsView {...props} />
        </>
      )} />
    </Switch>
  </BrowserRouter >
);



ReactDOM.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
