import React, { useRef, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import GeometryStore, { AnnotationGeometry, HighlightItem } from './../stores/geometryStore';
import { observer } from "mobx-react";
import { HeatmapStyle } from './popupView';


const useStyles = makeStyles((theme: Theme) => createStyles({
}))


export interface HeatmapImageProps {
  /** image width */
  width: number,

  /** image height */
  height: number,

  /** annotations geometry */
  geometry: Array<AnnotationGeometry>

  /** geometry store */
  store: GeometryStore
  //classes : any
  heatmapStyle: HeatmapStyle
  threshold: number
}


const HeatmapImage = (props: HeatmapImageProps) => {

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const store = props.store;


  useEffect(() => {
    if (canvasRef.current) {
      let canvas = canvasRef.current;
      let context = canvas.getContext("2d");
      if (context !== null) {
        console.log("redraw heatmap");
        context.globalAlpha = 1.0;
        context.clearRect(0, 0, props.width, props.height);
        if (props.geometry && props.geometry.length > 0) {
          if (store.bb) {
            context.fillStyle = "black";
            context.fillRect(0, 0, props.width, props.height);
            drawImage(context, store.bb, props.heatmapStyle);
            //drawBoard(context, 21, 21);
            if (store.heatmap) {
              drawHeatmap(context, store.heatmap, 21, 21, props.heatmapStyle, props.threshold);
            }
          }
        }
      }
    }
  }, [store.bb, store.heatmap, props.heatmapStyle, props.threshold])

  const marks = [
    {
      value: 0,
      label: '0°C',
    },
    {
      value: 20,
      label: '20°C',
    },
    {
      value: 37,
      label: '37°C',
    },
    {
      value: 100,
      label: '100°C',
    },
  ];


  const drawImage = (ctx: CanvasRenderingContext2D, bb: Array<number>, heatmapStyle: HeatmapStyle) => {

    let canvasWidth = ctx.canvas.width;
    let canvasHeight = ctx.canvas.height;


    let imgWidth = bb[2];
    let imgHeight = bb[3];


    let scale = Math.min(ctx.canvas.width / imgWidth, ctx.canvas.height / imgHeight);

    let offsetX = (canvasWidth / 2) - (imgWidth / 2) * scale;
    let offsetY = (canvasHeight / 2) - (imgHeight / 2) * scale;

    console.log("heatmap", canvasWidth, canvasHeight, imgWidth, imgHeight, offsetX, offsetY, bb);

    if (heatmapStyle === HeatmapStyle.Sun)
      ctx.filter = 'grayscale(100%)';

    ctx.drawImage(store.image, bb[0], bb[1], bb[2], bb[3], offsetX, offsetY, props.width - 2 * offsetX, props.height - 2 * offsetY);
    ctx.filter = 'none';
  }

  const drawBoard = (ctx: CanvasRenderingContext2D, rows: number, cols: number) => {
    console.log("draw board");
    let w = props.width / cols;
    let h = props.height / rows;

    for (let i = 0; i < rows; i++) {
      ctx.beginPath();
      ctx.moveTo(w * i, 0);
      ctx.lineTo(w * i, props.height);
      ctx.stroke();
    }

    for (let i = 0; i < cols; i++) {
      ctx.beginPath();
      ctx.moveTo(0, h * i);
      ctx.lineTo(props.width, h * i);
      ctx.stroke();
    }

  }

  const drawHeatmap = (ctx: CanvasRenderingContext2D, heatmap: Array<Array<number>>, rows: number, cols: number, heatmapStyle: HeatmapStyle, threshold: number) => {
    console.log("draw heatmap");

    let canvasWidth = ctx.canvas.width;
    let canvasHeight = ctx.canvas.height;


    let imgWidth = store.bb![2];
    let imgHeight = store.bb![3];


    let scale = Math.min(ctx.canvas.width / imgWidth, ctx.canvas.height / imgHeight);

    /*let offsetX = (canvasWidth / 2) - (imgWidth / 2) * scale;
    let offsetY = (canvasHeight / 2) - (imgHeight / 2) * scale;


    let w = (props.width - 2 * offsetX) / cols;
    let h = (props.height - 2 * offsetY) / rows;*/

    let offsetX = 0;
    let offsetY = 0;
    let w = props.width / cols;
    let h = props.height / rows;

    let pi2 = 2 * Math.PI;
    threshold = threshold / 100 * 255;

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        let color = 255 - heatmap[i][j];
        if (heatmap[i][j] >= threshold) {
          if (heatmapStyle === HeatmapStyle.Fill) {
            ctx.beginPath();
            ctx.rect(w * i + offsetX, h * j + offsetY, w, h);
            ctx.fillStyle = `hsl(${color},100%,50%)`;
            ctx.fill();
          } else if (heatmapStyle === HeatmapStyle.Dots) {
            ctx.beginPath();
            ctx.arc(w * i + w / 2 + offsetX, h * j + h / 2 + offsetY, 5, 0, pi2);
            ctx.fillStyle = `hsl(${color},100%,50%)`;
            ctx.fill();
          }
          else if (heatmapStyle === HeatmapStyle.Sun) {
            ctx.beginPath();
            ctx.rect(w * i + offsetX, h * j + offsetY, w, h);
            ctx.globalAlpha = heatmap[i][j] / 255;
            ctx.fillStyle = 'yellow';
            ctx.fill();
          }
        }
      }
    }
  }

  let classes = useStyles();
  return (
    <>
      <canvas ref={canvasRef} width={props.width} height={props.height} />
    </>
  )
}




export default HeatmapImage;