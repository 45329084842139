import resultsTable from 'components/resultsTable';
import { UserHistoryDTO, ImageAttributesDTO, ImageUploadDTO, LoginDTO, LogoutDTO, OptionsDTO, FRSListDTO, ShareDTO, RecalculationStatsDTO, LastResultsDTO, UsedFRSDTO, GetHeatmapDTO } from './dto/fpsDTO';
import { Filter } from './stores/mainViewStore';
import UIState from './stores/uiState';

const requests = {
  del: (uri: string) =>
    fetch(uri, { method: "delete" })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401)
            UIState.logOut();
          throw (response);
        }
        return response.json();
      }),
  get: (uri: string) =>
    fetch(uri, { method: "get" })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401)
            UIState.logOut();
          throw (response);
        }
        return response.json();
      }),
  post: (uri: string, body: string | FormData | null, headers: any/*,throwEx : boolean = true*/) =>
    fetch(uri, {
      method: "post",
      headers: headers,
      body: body
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401)
            UIState.logOut();
          //if (throwEx)    
          //  throw(response);
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1)
            return response.json();
          throw (response);
        }
        return response.json();
      })
}


const uploadFile = (file: File) => {
  let data = new FormData();
  data.append("file", file);
  return data;
}

const auth = (uri: string) => {
  if (!UIState.token)
    return uri;

  let qs = uri.indexOf('?');
  if (qs == -1)
    return `${uri}?token=${UIState.token}`;
  else
    return `${uri}&token=${UIState.token}`;
}

const FoodRecognitionAPI = {
  /** получение истории распознаваний для пользователя */
  userHistory: (skip: number, limit: number): Promise<UserHistoryDTO> => requests.get(`/api/userHistory?skip=${skip}&limit=${limit}`),
  /** ссылка на файл с картинкой на сервере */
  download: (image: string, shared: boolean, newName: string = "") => auth(`/api/download/${image}?shared=${shared}&newName=${newName}`),
  /** ссылка на файл с thumbnail картинкой на сервере */
  thumbnail: (image: string, shared: boolean) => `/api/thumbnail/${image}?shared=${shared}`,
  /** удаление картинки и результатов из базы */
  removeImage: (image: string) => requests.del(`/api/image/${image}`),
  /** update заметок и тега для картинки в базе */
  updateImage: (image: string, attributes: ImageAttributesDTO) => requests.post(`/api/image/${image}`, JSON.stringify({ attributes: attributes }), { "Content-Type": "application/json" }),
  /** закачивание картинки на сервер и получение результатов распознавания */
  imageUpload: (image: File): Promise<ImageUploadDTO> => requests.post(`/api/upload?ver=v2`, uploadFile(image), {}),
  /** логин пользователя */
  login: (token: string): Promise<LoginDTO> => requests.post('/api/login', null, { "Authorization": `Basic ${token}` }),
  /** логаут пользователя */
  logout: (): Promise<LogoutDTO> => requests.get('/api/logout'),
  /** регистрация пользователя */
  signup: (token: string): Promise<LoginDTO> => requests.post('/api/signup', null, { "Authorization": `Basic ${token}` }),
  /** получение списка frs с сервера */
  frsList: (): Promise<FRSListDTO> => requests.get('/api/frsList'),
  /** получение списка frs с сервера */
  usedFrs: (): Promise<UsedFRSDTO> => requests.get('/api/usedFrs'),
  /** сохранение настроек пользователя в базе */
  saveOptions: (filter: Filter) => requests.post(`/api/options`, JSON.stringify({ filter: filter }), { "Content-Type": "application/json" }),
  /** получение настроек пользователя из базы */
  options: (): Promise<OptionsDTO> => requests.get('/api/options'),
  /** расшарить картинку  */
  share: (image: string): Promise<ShareDTO> => requests.post(`/api/share/${image}`, "", {}),
  /** получение расшаренных изображений  */
  shared: (skip: number, limit: number): Promise<UserHistoryDTO> => requests.get(`/api/shared?skip=${skip}&limit=${limit}`),
  /** получение статистики процесса рекалькуляции изображений */
  recalculationStats: (): Promise<RecalculationStatsDTO> => requests.get('/api/recalculationStats'),
  /** получение последних результатов для одной фотографии */
  lastResults: (image: string): Promise<LastResultsDTO> => requests.get(`/api/lastResults/${image}?token=${UIState.token}`),
  /** загрузка хитмапа для фотографии  */
  getHeatmap: (image: string, frsName: string): Promise<GetHeatmapDTO> => requests.get(`/api/getHeatmap/${image}?frs=${frsName}`)
}


export default FoodRecognitionAPI;
