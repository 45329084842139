import React from 'react';
import i18next from 'i18next';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button, Theme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { observer } from "mobx-react"
import mainStore from "./../stores/mainStore"
import foodRecognitionAPI from './../recognitionAPI'
import DialogWindow from './dialogWindow'
import FoodImage from './foodImage'
import { findTopCandidate } from './../utils'
import { ImageUploadFailedDTO } from './../dto/fpsDTO'

const styles = createStyles((theme: Theme) => ({
  fileInput: {
    borderBottom: "1px solid ",
    borderRight: "1px solid ",
    borderTop: "1px solid ",
    borderLeft: "1px solid ",
    padding: "10px",
    margin: "15px",
    cursor: "pointer"
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    marginLeft: 20,
    zIndex: 1,
  },
  button: {
    marginTop: 16,
    marginBottom: 16,
    maxWidth: 120,
  }
}))

export interface ImageUploadProps {
  /** allow send multiple files  */
  multiple: boolean,
  classes: any
}

/** Image upload control */
const ImageUpload = observer(
  class ImageUpload extends React.Component<ImageUploadProps> {

    //state = new ImageUploadState()
    state = mainStore.imageUploadStore

    loadPhoto(target: HTMLInputElement, index: number) {
      if (target && target.files) {
        this.state.startImageLoading();
        let reader = new FileReader();
        let file = target.files[index];
        reader.onloadend = () => {
          this.state.onImageLoaded(reader.result as string);
          mainStore.mainViewStore.updateRecongitionResult(false);
          foodRecognitionAPI.imageUpload(file).then(response => {
            target.value = ""; //clear input
            //this.state.onImageUploaded(response.data.file);
            if (response.success) {
              this.state.onImageUploaded(response.data.image);
              this.state.onRequestSuccess();
              let candidate = findTopCandidate(response.data.versions[0].data, () => true);
              mainStore.mainViewStore.addToHistory(response.data);
              mainStore.mainViewStore.updateRecongitionResult(true, candidate.name);

            } else {
              this.state.onImageUploaded(response.data.file);
              if ((response as ImageUploadFailedDTO).status === 1) //image exists
                this.state.onImageExists(response.message)
              else
                this.state.onRequestFail(response.message);
            }
          }).catch(err => {
            if (err.status === 413)
              this.state.onRequestFail(i18next.t("file_too_large"));
            else
              this.state.onRequestFail(i18next.t("upload_failed"));
          });
        }
        reader.readAsDataURL(file);
      }
    }

    _handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
      e.preventDefault();
      if (e.target.files && e.target.files.length > 0)
        this.loadPhoto(e.target, 0);
    }

    onWarningDialogClosed(action: number) {
      this.state.closeDialog();
      if (action === 1) { //show
        mainStore.mainViewStore.fetchAndOpenHistory(this.state.fileUploaded);
      }
    }

    removeImage(file: string) {
      foodRecognitionAPI.removeImage(file).then(result => {
        this.state.reset();
        mainStore.mainViewStore.updateRecongitionResult(false);
        mainStore.mainViewStore.removeImage(file);
      })
    }

    render() {
      let classes = { ...this.props.classes };

      return (
        <Grid container direction="column"  >
          <Grid item >
            <form /*onSubmit={(e) => this._handleSubmit(e)}*/>
              <input className={classes.fileInput}
                type="file"
                accept=".jpg, .jpeg, .png"
                multiple={this.props.multiple}
                onChange={(e) => this._handleImageChange(e)} />
              {this.state.loading &&
                <CircularProgress size={68} className={classes.fabProgress} />
              }
            </form>
          </Grid>
          <Grid>
            {this.state.image &&
              <FoodImage image={this.state.image} download={false} alias={"uploaded_image"} width={300} height={300} shared={false} />}
          </Grid>
          {<Grid>
            <Button variant="contained" className={classes.button} disabled={this.state.fileUploaded === ""} onClick={(e) => this.removeImage(this.state.fileUploaded)}> {i18next.t('delete')} </Button>
          </Grid>}
          {this.state.error &&
            <p style={{ color: "red" }}>{this.state.errorMsg}</p>
          }
          <DialogWindow
            open={this.state.showWarning}
            onClose={(action) => { this.onWarningDialogClosed(action) }}
            title=""
            text={i18next.t('show_details_dialog_text')}
            buttons={[i18next.t('no'), i18next.t('yes')]}
          />
        </Grid>
      )
    }
  })

export default withStyles(styles)(ImageUpload);


