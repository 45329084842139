import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Theme, Grid, Button, Typography, IconButton } from '@material-ui/core';
import { TabPanel, TabContext } from '@material-ui/lab';
import ImageUpload from './imageUpload'
import RecognitionResultsTable from './resultsTable'
import HistoryView from './historyView/historyView'
import { toJS } from "mobx"
import { observer } from "mobx-react"
import i18next from 'i18next';
import mainStore from "./../stores/mainStore"
import PopupView from './popupView';
import foodRecognitionAPI from '../recognitionAPI';
import FRSSelect from './frsSelect';
import LoginView from './loginView';
import UIState, { AuthenticateStatus } from './../stores/uiState';
import DialogWindow from './dialogWindow';
import { MainViewTabs } from './../stores/mainViewStore';
import { FRSFilterOption } from './../dto/fpsDTO';
import { Filter } from './../stores/mainViewStore';
import RecalculationView from './recalculationView';
import { RecalculationData } from './../dto/fpsDTO';
import ImagePopup from './imagePopup'

const styles = createStyles((theme: Theme) => ({
  selectImg: {
    width: 32,
    height: 32,
    padding: 8
  }
}));


export interface MainViewProps {
  classes: any
}


export interface ITableOptions {
  deleteRecord: boolean,
  editTag: boolean,
  editNotes: boolean,
  shareRecord: boolean
  downloadShared: boolean
}

class TableOptions implements ITableOptions {

  /** показывать в таблице колонку с удалением записи */
  deleteRecord: boolean
  /** возможность редактирования тэга */
  editTag: boolean
  /** возможность добавления/редактирования заметок */
  editNotes: boolean
  /** возможность расшаривания записи */
  shareRecord: boolean
  /** загружать картинки из shared каталога */
  downloadShared: boolean

  constructor(deleteRecord: boolean, editTag: boolean, editNotes: boolean, shareRecord: boolean, downloadShared: boolean) {
    this.deleteRecord = deleteRecord;
    this.editTag = editTag;
    this.editNotes = editNotes;
    this.shareRecord = shareRecord;
    this.downloadShared = downloadShared;
  }
}


const MainView = observer((props: MainViewProps) => {

  const store = mainStore.mainViewStore;
  const geometryStore = mainStore.geometryStore;

  const historyTableOptions = new TableOptions(true, true, true, true, false);
  const sharedTableOptions = new TableOptions(false, false, false, false, true);

  /** текущая вкладка Tab */
  //const [currentTab,setTab]  = useState(MainViewTabs.TAB_LOAD_PHOTO);

  /** ошибка логина/регистрации */
  const [loginError, setLoginError] = useState<string>("");

  /** показывать ли popup логина/регистрации */
  const [showLogin, showLoginDialog] = useState<boolean>(false);

  const [showRecalculation, showRecalculationDialog] = useState<boolean>(false);

  const [recalculationStats, setRecalculationStats] = useState<RecalculationData | undefined>(undefined);


  useEffect(() => {
    //console.log("use effect",UIState.authenticated);
    if (UIState.authenticated === AuthenticateStatus.Aunhenticated)
      init();
    else if (UIState.authenticated === AuthenticateStatus.NotAuthenticated)
      showLoginDialog(true);
    else if (UIState.authenticated === AuthenticateStatus.Unknown)
      UIState.authenticate();
  }, [UIState.authenticated]);

  useEffect(() => {
    let timer = setInterval(() => {
      foodRecognitionAPI.recalculationStats().then(result => {
        if (result.data.size > 0) {
          setRecalculationStats(result.data);
          showRecalculationDialog(true);
        } else {
          showRecalculationDialog(false);
          clearTimeout(timer);
        };
      });
    }, 1000);
  }, [])

  const updateTab = (tab: MainViewTabs) => {
    if (tab === MainViewTabs.TAB_HISTORY) {
      store.setTab(tab);
      /*store.fetchHistory().then(() => {
        //setTab(tab) 
      });*/
    } else if (tab === MainViewTabs.TAB_LOAD_PHOTO) {
      //store.clearHistory();
      store.setTab(tab);
    } else if (tab === MainViewTabs.TAB_SHARED_IMAGES) {
      //setTab(tab);
      store.fetchShared().then(() => {
      })
    }
  }

  /** процедура логина/регистрации */
  const signin = (login: string, password: string, isSignIn: boolean) => {
    const token = Buffer.from(`${login}:${password}`, 'utf8').toString('base64');
    //console.log("login:",login," password:",password,"token:",token);
    let promise;
    if (isSignIn)
      promise = foodRecognitionAPI.login(token);
    else
      promise = foodRecognitionAPI.signup(token);

    promise.then(result => {
      console.log(result);
      if (!result.success) {
        setLoginError(result.data.error);
        return;
      }

      setLoginError("");
      showLoginDialog(false);
      UIState.logIn(result.data.user_id);
      //init();
    }).catch(err => {
      console.log(err);
      if (err.statusText)
        setLoginError(err.statusText);
      else
        setLoginError(i18next.t('unknown_error'));
    });
  }

  /**  инициализация настроек в случае успешного логина */
  const init = () => {
    let promiseOptions = foodRecognitionAPI.options();
    let promiseFRSList = foodRecognitionAPI.frsList();
    let promiseUsedFrs = foodRecognitionAPI.usedFrs();

    let filter: Filter | undefined = undefined;
    promiseOptions.then(result => {
      if (result.data.options && result.data.options.frs) {
        filter = result.data.options;
      }
    });
    promiseFRSList.then(result => {
      store.setFRSConfig(result.data);
    });
    promiseUsedFrs.then(result => {
      store.setFRS(result.data);
    });

    Promise.all([promiseOptions, promiseFRSList, promiseUsedFrs]).then(() => {
      if (!filter) {
        store.syncFrs();
        store.setFilter({
          frs: store.frs.map(el => {
            return {
              "name": el,
              "active": true
            }
          })
        });
      } else {
        //добавляем новые frs в фильтр
        let added: Array<FRSFilterOption> = [];
        store.frs.forEach(frs => {
          let found = filter!.frs.find(el => el.name === frs);
          if (!found) {
            added.push({
              "name": frs,
              "active": true
            })
          }
        });
        if (added.length > 0) {
          filter.frs = filter.frs.concat(added);
        }
        store.setFilter(filter);
      }

      //загрузка истории
      store.loadHistory();
    });
  }

  /** выход  */
  const logout = () => {
    foodRecognitionAPI.logout().then(result => {
      UIState.logOut();
      //showLoginDialog(true);
      store.setTab(MainViewTabs.TAB_LOAD_PHOTO);
    });
  }

  const showPopup = () => {
    store.openLastImage();
    store.setTab(MainViewTabs.TAB_HISTORY);
  }

  const classes = { ...props.classes };

  return (
    <>
      <TabContext value={store.currentTab} >
        <Grid container direction="row" wrap="nowrap" spacing={10} style={{ width: "100%" }}>
          <Grid item>
            <Tabs value={store.currentTab} onChange={(e, val) => updateTab(val)} >
              <Tab label={i18next.t('load_photo')} value={MainViewTabs.TAB_LOAD_PHOTO} />
              <Tab label={i18next.t('request_history')} value={MainViewTabs.TAB_HISTORY} disabled={store.filter.frs.length === 0} />
              <Tab label={i18next.t('shared_images')} value={MainViewTabs.TAB_SHARED_IMAGES} disabled={store.filter.frs.length === 0} />
            </Tabs>
          </Grid>
          <Grid style={{ alignSelf: "center" }}>
            <IconButton disabled={store.filter.frs.length === 0} onClick={(e) => store.openFRSSelectDialog()}>
              <img src={process.env.PUBLIC_URL + '/images/select.png'} alt="/images/select.png" className={classes.selectImg} />
            </IconButton>
          </Grid>
          <Grid style={{ alignSelf: "center", paddingLeft: "32px" }}>
            <Typography>
              {i18next.t('display_user', { user: UIState.user })}
            </Typography>
          </Grid>
          <Grid style={{ alignSelf: "center", paddingLeft: "32px" }}>
            <Button variant="contained" onClick={(e) => logout()}> {i18next.t('logout')} </Button>
          </Grid>
        </Grid>
        <TabPanel value={MainViewTabs.TAB_LOAD_PHOTO} >
          <ImageUpload multiple={false} />
          <RecognitionResultsTable records={store.uploadDetailsView} topCandidate={store.topCandidate} mobile={false} loadControls={false} store={geometryStore} />
          <Button variant="contained" onClick={(e) => showPopup()} style={{ marginTop: 40 }} > {i18next.t('show_last_image')} </Button>
          {store.openHistoryDetails &&
            <PopupView open={true} downloadShared={false} store={geometryStore} />
          }
        </TabPanel>
        <TabPanel value={MainViewTabs.TAB_HISTORY} >
          <HistoryView order={store.order} orderBy={store.orderBy} versions={store.getVersions} options={historyTableOptions} />
          {store.openPopup &&
            <PopupView open={true} downloadShared={historyTableOptions.downloadShared} store={geometryStore} />
          }
        </TabPanel>
        <TabPanel value={MainViewTabs.TAB_SHARED_IMAGES} >
          <HistoryView order={store.order} orderBy={store.orderBy} versions={store.getVersions} options={sharedTableOptions} />
          {store.openPopup &&
            <PopupView open={true} downloadShared={sharedTableOptions.downloadShared} store={geometryStore} />
          }
        </TabPanel>
      </TabContext>
      {store.openFRSSelect &&
        <FRSSelect open={true} frs={store.filter.frs} /*frsFiltered={store.filter.frs}*/ onClose={(bSave, frs) => store.closeFRSSelectDialog(bSave, frs)} />
      }

      {showLogin && !showRecalculation &&
        <LoginView open={true} onSignIn={(login, password, isSignIn) => signin(login, password, isSignIn)} error={loginError} />
      }
      {showRecalculation &&
        <RecalculationView open={true} stats={recalculationStats!} />
      }
      {store.error &&
        <DialogWindow open={true} text={store.error} buttons={[i18next.t("OK")]} onClose={(e) => store.setError(undefined)} />
      }
    </>

  )
})




export default withStyles(styles)(MainView);


