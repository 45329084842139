import React,{useState} from 'react';
import { withStyles,createStyles } from '@material-ui/core/styles';
import { TextField,Dialog,DialogContent,Theme,DialogTitle,Grid } from '@material-ui/core';
import ProgressBar from "@ramonak/react-progress-bar";
import i18next from 'i18next';
import {RecalculationData} from './../dto/fpsDTO'; 

const styles = (theme : Theme) => createStyles({  
  button: {
    maxWidth : 200,
    height : 40,
    marginLeft : 16
  },
  popup : {
    marginRight : 100
  },
  textNotes : {
    width:"90%",
    marginLeft:"5%",
    marginRight:"5%"
  }
})


 export interface RecalculationViewProps    {
   /** открыт ли popup */
  open: boolean,
  stats : RecalculationData
  classes : any
}


/** Popup для логина/регистрации */
const RecalculationView = (props : RecalculationViewProps) => {
 

  const showCompleted = () => {
      if (props.stats) {
        let completed : number = (props.stats.processed / (props.stats.size + props.stats.processed + props.stats.pending)) * 100;
        return Math.floor(completed); 
      }
      return 0;
  }

  const  showProgess = () => {
    let total = props.stats.size + props.stats.processed + props.stats.pending;
    let processed = props.stats.processed;
    return `${processed}/${total}`;
  }
   
  const classes = { ...props.classes };

  return (    
    <Dialog 
       open = {props.open}
       classes = {{
        paper : classes.popup
      }}
       >
         <DialogContent style={{width : "280px",height : "120px"}}>
           <Grid container direction = "column" spacing={2}>
             <Grid item style={{textAlign:"center"}}>
                {i18next.t('waiting_recalculation')}
             </Grid>
           <Grid item>
              <ProgressBar completed={showCompleted()}></ProgressBar>
           </Grid>
           <Grid item style={{textAlign:"center"}}>
            {showProgess()}   
           </Grid>         
         </Grid>
         </DialogContent>         
       </Dialog> 
  )
}

 export default withStyles(styles)(RecalculationView);
 




