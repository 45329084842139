import { makeAutoObservable, action, runInAction } from "mobx"
import { onlyUnique, findTopCandidate, generateQuickGuid } from './../utils'
import foodRecognitionAPI from './../recognitionAPI'
import { stableSort, comparer, SortOrder } from "./../sort"
import { RecognitionHistoryDTO, BRSVersionResponseDTO, ModelResponseDTO, UserHistoryDTO, FRSFilterOption, FrsConfig, GetHeatmapDTO, RecognitionResultDTO } from './../dto/fpsDTO';
import { cloneDeep } from "lodash"
import mainStore from "./mainStore"
import GeometryStore from './geometryStore'

export enum MainViewTabs {
    TAB_LOAD_PHOTO = "0",
    TAB_HISTORY = "1",
    TAB_SHARED_IMAGES = "2"
};



export interface BRSVersionResponse extends BRSVersionResponseDTO {
    topCandidate: string,
    topConfidency: string,
    topFRS: string,
    data: Array<ModelResponse>
}

export interface RecognitionHistory extends RecognitionHistoryDTO {
    tag?: string,
    notes?: string,
    shared?: boolean,
    timestamp: string,
    frs: Array<string>,
    versions: Array<BRSVersionResponse>
}

export interface ModelResponse extends ModelResponseDTO {
    results: Array<RecognitionResult>
}


export interface RecognitionResult extends RecognitionResultDTO {
    showInner?: boolean,
    key?: string
}


export interface Filter {
    frs: Array<FRSFilterOption>
}

/** делегат для фильтра: включение в выборку только результатов от выбранных frs */
const isFiltered = (version: ModelResponseDTO, filter: Filter): boolean => {
    return filter.frs.filter(el => el.active).map(el => el.name).includes(version.frs_name);
}

export default class MainViewStore {
    /** ответ с сервера на загруженное изображение */
    //recognitionResult: Array<ModelResponseDTO> = []
    /** топ-кандидат для загруженного изображения */
    topCandidate?: string = undefined
    /** название frs с топ-кандидатом */
    //topFRS? : string = undefined
    /** данные с результатами распознавания для отображения в таблице */
    history: Array<RecognitionHistory> = []
    /** shared данные с результатами распознавания для отображения в таблице */
    historyShared: Array<RecognitionHistory> = []
    /** отображаемые версии frs */
    versions: Array<number> = []
    /** отображаемые shared версии frs */
    sharedVersions: Array<number> = []
    /** список с названиями всех frs */
    frs: Array<string> = []
    /**   фильтр с выбранными frs */
    filter: Filter = {
        frs: []
    }
    /**open popup */
    openPopup: boolean = false
    /** открыт ли detailsDialog*/
    openVersionDetails: boolean = false
    /** открыт ли historyDetailsDialog*/
    openHistoryDetails: boolean = false
    /** картинка для detailsDialog или для historyDetailsDialog */
    image?: string = undefined
    /** версия для  detailsDialog */
    version?: number = undefined
    /** текущий порядок сортировки  в таблице*/
    order: SortOrder = "desc"
    /** текущее поле для сортировки в таблице*/
    orderBy: string = "timestamp"
    /** открыт ли диалог с настройками*/
    openFRSSelect: boolean = false
    /** текст ошибки для popup */
    error: string | undefined = undefined
    /** текущая вкладка */
    currentTab: MainViewTabs = MainViewTabs.TAB_LOAD_PHOTO
    /** список с названиями frs для комобокса*/
    frsList: Array<string> = []
    /** текущий frs для комбобокса */
    currentFRS: string = "All"
    /** массив со всеми картинками для быстрого перехода между картинками не закрывая popup */
    images: Array<string> = []
    /** результаты ответа для details view */
    lastResults: Array<ModelResponseDTO> = []
    /** данные frs.config */
    frsConfig: Array<FrsConfig> = []
    /** показывать значок загрузки в popup? */
    showPopupLoading: boolean = false
    /** показывать данные на вкладке Load Photo */
    showUploaded: boolean = false
    showImagePopup: boolean = false

    constructor() {
        makeAutoObservable(this, {
            // history : false
        });
    }

    reset() {
        //this.recognitionResult = []
        this.topCandidate = undefined
        this.history = []
        this.historyShared = []
        this.versions = []
        this.sharedVersions = []
        this.frs = []
        this.filter.frs = []
        this.openPopup = false
        this.openVersionDetails = false
        this.openHistoryDetails = false
        this.image = undefined
        this.version = undefined
        this.order = "desc"
        this.orderBy = "timestamp"
        this.openFRSSelect = false
        this.error = undefined
        this.currentTab = MainViewTabs.TAB_LOAD_PHOTO
        this.frsList = []
        this.currentFRS = "All"
        this.images = []
        this.lastResults = []
        this.frsConfig = []
        this.showPopupLoading = false
        this.showUploaded = false
        this.showImagePopup = false
    }

    /** запись ответа с сервера на загруженное изображение*/
    updateRecongitionResult(showUploaded: boolean,/*result: Array<ModelResponseDTO>,*/ topCandidate?: string) {
        //this.recognitionResult = result;
        this.topCandidate = topCandidate;
        this.showUploaded = showUploaded;
    }

    /** отображение истории распознаваний для загруженного изображения в случае, когда данное изображение уже есть в базе */
    fetchAndOpenHistory(image: string) {
        /*if (this.history.length === 0) {
            let skip = 0;
            let limit = 20;
            this.fetchHistory(skip, limit).then(() => {
                this.openHistoryDetailsDialog(image);
            })
        } else*/ {
            this.openHistoryDetailsDialog(image);
        }
    }

    /** первичная обработка или пересчет данных 
     *  в зависимости от выбранных frs расчитываем топ-кандидата
     */
    recalculateData(history: Array<RecognitionHistory>, versions: Array<number>, isShared: boolean) {
        let versionsArr: Array<number> = Array.from(versions);
        for (const el of history) {
            for (const version of el.versions as Array<BRSVersionResponse>) {
                let topCandidate = findTopCandidate(version.data, ((el) => isFiltered(el, this.filter)));
                if (topCandidate.name !== "") {
                    version.topCandidate = topCandidate.name;
                    version.topConfidency = topCandidate.confidency;
                    version.topFRS = topCandidate.frs_name;
                    versionsArr.push(version.version);
                }
                for (const d of version.data) {
                    for (let r of d.results) {
                        r.showInner = false;
                        r.key = generateQuickGuid();
                        if (r.inner) {
                            for (let res of r.inner.results as Array<RecognitionResult>) {
                                res.key = generateQuickGuid();
                            }
                        }
                    }
                }
            }
        }
        let v = versionsArr.filter(onlyUnique).sort((a, b) => a - b).reverse();
        if (isShared)
            this.sharedVersions = v;
        else
            this.versions = v
    }

    /** добавление результатов распознавания после загрузки картинки к основным данным */
    addToHistory(el: RecognitionHistoryDTO) {
        let record = el as RecognitionHistory;

        if (el.imageInfo) {
            record.tag = el.imageInfo.tag;
            record.notes = el.imageInfo.notes;
            record.shared = el.imageInfo.shared;
        }
        if (!record.shared) {
            record.shared = false;
        }

        for (const version of el.versions as Array<BRSVersionResponse>) {
            let topCandidate = findTopCandidate(version.data, ((el) => isFiltered(el, this.filter)));
            if (topCandidate.name !== "") {
                version.topCandidate = topCandidate.name;
                version.topConfidency = topCandidate.confidency;
                version.topFRS = topCandidate.frs_name;
            }

            for (const d of version.data) {
                for (let r of d.results) {
                    r.showInner = false;
                    r.key = generateQuickGuid();
                    if (r.inner) {
                        for (let res of r.inner.results as Array<RecognitionResult>) {
                            res.key = generateQuickGuid();
                        }
                    }
                }
            }
        }

        this.history.unshift(record);
    }



    loadHistory() {
        this.history = [];

        (async () => {
            let skip = 0
            let limit = 20;
            let history: Array<RecognitionHistory> = [];
            do {
                history = await this.fetchHistory(skip, limit);
                this.recalculateData(history, this.versions, false);
                runInAction(() => {
                    this.history = this.history.concat(history);
                })
                skip = skip + limit;
                await new Promise(r => setTimeout(r, 1000));
            } while (history.length === limit)
        })();
    }

    /** получение истории распознаваний с сервера */
    fetchHistory(skip: number, limit: number) {
        console.log("fetch history");
        return new Promise<Array<RecognitionHistory>>((resolve, reject) => {
            foodRecognitionAPI.userHistory(skip, limit).then(action("prepare data", (result: UserHistoryDTO) => {
                let history = result.data as Array<RecognitionHistory>
                for (const el of history) {
                    if (el.imageInfo) {
                        el.tag = el.imageInfo.tag;
                        el.notes = el.imageInfo.notes;
                        el.shared = el.imageInfo.shared;
                    }
                    if (!el.shared) {
                        el.shared = false;
                    }
                }
                resolve(history)
                /*this.history = history;
                this.recalculateData();
                this.setTab(MainViewTabs.TAB_HISTORY);
                console.log("history fetched");
                resolve();*/
            })).catch(err => {
                reject(err);
            });
        });
    }

    removeImage(image: string) {
        this.history = this.history.filter(el => el.image !== image);
    }

    /*clearHistory() {
        this.history = [];
        this.versions = [];
    }*/

    fetchShared() {
        console.log("fetch shared");
        return new Promise<void>((resolve, reject) => {
            foodRecognitionAPI.shared(0, 10000).then(action("prepare data", (result: UserHistoryDTO) => {
                let history = result.data as Array<RecognitionHistory>
                for (const el of history) {
                    //let {tag , notes} = (el.imageInfo && el.imageInfo.attributes.length > 0) ? el.imageInfo.attributes[0] : {tag : "",notes : ""} ;   
                    // el.tag = tag;
                    // el.notes = notes;
                    if (el.imageInfo) {
                        el.tag = el.imageInfo.tag;
                        el.notes = el.imageInfo.notes;
                        el.shared = el.imageInfo.shared;
                    }
                }

                this.recalculateData(history, this.sharedVersions, true);
                this.historyShared = history;
                this.setTab(MainViewTabs.TAB_SHARED_IMAGES);
                console.log("shared fetched");
                resolve();
            })).catch(err => {
                reject(err);
            });
        });
    }

    /** изменение текущей версии в popup */
    setVersion(newVersion: BRSVersionResponse | null, store: GeometryStore | undefined /*updateGeometry = true*/) {
        if (newVersion) {
            this.version = newVersion.version;
            this.topCandidate = newVersion.topCandidate;
            this.updateFRSList(newVersion.data);
            if (store)
                store.loadGeometry(this.currentFRS, this.versionDetailsView);
        }
    }

    nextVersion(store: GeometryStore) {
        this.setVersion(this.getNextVersion(-1), store);
    }

    hasNextVersion() {
        return (this.getNextVersion(-1) !== null);
    }

    previousVersion(store: GeometryStore) {
        this.setVersion(this.getNextVersion(1), store);
    }

    hasPreviousVersion() {
        return (this.getNextVersion(1) !== null);
    }

    getNextVersion(diff: number) {
        let row = this.historyData.filter(el => el.image === this.image)[0] as RecognitionHistory;
        let versions = row.versions.map(el => el.version);
        let i = versions.indexOf(this.version!);
        if (diff === -1 && i > 0)
            return row.versions[i - 1];
        if (diff === 1 && i < versions.length - 1)
            return row.versions[i + 1];
        return null;
    }

    setImage(newImage: string | null) {
        if (newImage) {
            this.image = newImage;
            //проверка: имеет ли выбранная картинка нужную версию
            let row = this.historyData.filter(el => el.image === this.image)[0] as RecognitionHistory;
            let versions = row.versions.map(el => el.version);
            let i = versions.indexOf(this.version!);
            if (i === -1) {
                if ((Number(versions[0]) - this.version!) === Math.abs(1))
                    this.setVersion(row.versions[0], undefined);
                else
                    this.setVersion(row.versions[row.versions.length - 1], undefined);
                console.log("no version:", versions);
            }

        }
    }

    nextImage() {
        this.setImage(this.getNextImage(-1));
    }

    previousImage() {
        this.setImage(this.getNextImage(1));
    }

    hasNextImage() {
        return (this.getNextImage(-1) !== null);
    }

    hasPreviousImage() {
        return (this.getNextImage(1) !== null);
    }

    getNextImage(diff: number) {
        let i = this.images.indexOf(this.image!);
        if (diff === -1 && i > 0)
            return this.images[i - 1];
        if (diff === 1 && i < this.images.length - 1)
            return this.images[i + 1];
        return null;
    }

    setImages(images: Array<string>) {
        this.images = images;
    }

    updateFRSList(data: ModelResponseDTO[]) {
        let filtered = data.filter(el => isFiltered(el, this.filter));
        let frsList = filtered.map(el => el.frs_name);
        frsList.unshift("All");
        this.frsList = frsList;
    }

    /** открыть popup с последней картинкой */
    openLastImage() {
        let row = this.historyData[0];
        let versions = row.versions[0];
        this.openVersionDetailsDialog(versions.version, row.image, versions.topCandidate);
    }

    /** открыть диалог с историческими данными для выбранного изображения и версии*/
    openVersionDetailsDialog(version: number, image: string, topCandidate: string) {
        this.openPopup = true;
        this.openVersionDetails = true;
        this.version = version;
        this.image = image;
        this.topCandidate = topCandidate;
        this.currentFRS = "All" //topFRS;

        let row = this.historyData.filter(el => el.image === this.image)[0] as RecognitionHistory;
        let data = row.versions.filter(el => el.version === Number(this.version))[0].data;
        this.updateFRSList(data);
    }
    /** закрыть диалог с историческими данными для выбранного изображения и версии*/
    closeVersionDetailsDialog() {
        this.openVersionDetails = false;
    }

    /** открыть диалог с историческими данными для выбранного изображения*/
    openHistoryDetailsDialog(image: string) {
        this.openPopup = true;
        this.openHistoryDetails = true;
        this.image = image;
    }

    /** закрыть диалог с историческими данными для выбранного изображения*/
    closeHistoryDetailsDialog() {
        this.openHistoryDetails = false;
    }

    /** закрыть popup */
    closePopup() {
        this.openPopup = false;
        this.openHistoryDetails = false;
        this.openVersionDetails = false;
    }

    /** открыть диалог с настройками */
    openFRSSelectDialog() {
        this.openFRSSelect = true;
    }

    /** закрыть диалог с настройками
     *  сохранение настроек в базе и пересчет данных
     */
    closeFRSSelectDialog(bSave: boolean, frsFiltered: Array<FRSFilterOption>) {
        this.openFRSSelect = false;
        if (bSave) {
            this.filter.frs = frsFiltered;
            foodRecognitionAPI.saveOptions(this.filter);
            this.versions = [];
            this.recalculateData(this.history, this.versions, false);
            this.sharedVersions = [];
            this.recalculateData(this.historyShared, this.sharedVersions, true);
        }
    }

    /** запись тега для изображения в базу и в историю*/
    updateTag(image: string, tag: string) {
        console.log("update tag");
        foodRecognitionAPI.updateImage(image, { tag: tag }).then(action(() => {
            let row = this.historyData.filter(el => el.image === image);
            if (row.length > 0)
                row[0].tag = tag;
        }))
    }

    /** запись заметок для изображения в базу и в историю*/
    updateNotes(image: string, notes?: string) {
        console.log("update notes");
        foodRecognitionAPI.updateImage(image, { notes: notes }).then(action(() => {
            let row = this.historyData.filter(el => el.image === image);
            if (row.length > 0)
                row[0].notes = notes;
        }))
    }

    updateShare(image: string) {
        console.log("update share");
        foodRecognitionAPI.share(image).then(action("updating share", result => {
            console.log(result);
            if (result.success) {
                let row = this.historyData.filter(el => el.image === image);
                if (row.length > 0)
                    row[0].shared = true;
            } else {
                this.setError(result.message);
            }
        }))
    }

    /** изменение поля для сортировки и порядка сортировки в таблице */
    updateOrder(orderBy: string) {
        const isDesc = this.orderBy === orderBy && this.order === 'desc';
        this.order = isDesc ? 'asc' : 'desc';
        this.orderBy = orderBy;
    }

    setFRS(frs: Array<string>) {
        this.frs = frs;
        //this.filter.frs = frs;        
    }

    setFRSConfig(frsConfig: Array<FrsConfig>) {
        this.frsConfig = frsConfig;
    }

    syncFrs() {
        for (let el of this.frsConfig) {
            if (!this.frs.includes(el.name))
                this.frs.push(el.name);
        }
    }

    hasHeatmap(frsName: string) {
        let frs = this.frsConfig.find(el => el.name === frsName);
        return frs && frs.heatmap;
    }

    updatePolygons(frsName: string, results: Array<RecognitionResultDTO>) {
        let row = this.history.find((el: RecognitionHistory) => el.image === this.image);
        console.log(row);
        if (row) {
            let v = row.versions.find((el: BRSVersionResponse) => el.version === this.version);
            if (v) {
                let f = v.data.find((el: ModelResponseDTO) => el.frs_name === frsName);
                if (f) {
                    f.results = results;
                }
            }
        }
    }

    setFilter(filter: Filter) {
        this.filter = filter;
    }

    setError(error: string | undefined) {
        this.error = error;
    }

    setTab(tab: MainViewTabs) {
        this.currentTab = tab;
    }

    /** переключение режима отображения popupа на отображение всех версий*/
    switchAll() {
        this.closeVersionDetailsDialog();
        this.openHistoryDetailsDialog(this.image!);
    }

    /** переключение режима отображения popupа на отображение конкретной версии */
    switchVersion(version: number, topCandidate: string, topFRS: string) {
        this.closeHistoryDetailsDialog();
        this.openVersionDetailsDialog(version, this.image!, topCandidate);
    }

    setCurrentFRS(frs: string) {
        this.currentFRS = frs;
        /*if (frs !== "All") {
            // mainStore.geometryStore.setHighlight(0, frs);
        }*/
    }

    clearShowInner() {
        let row = this.historyData.filter(el => el.image === this.image)[0] as RecognitionHistory;
        let data = row.versions.filter(el => el.version === Number(this.version))[0].data;
        for (let d of data) {
            for (let r of d.results) {
                r.showInner = false;
            }
        }
    }

    /** получение данных отображаемых на вкладке Load Photo */
    get uploadDetailsView(): Array<ModelResponseDTO> {
        console.log("uploadDetails");
        if (this.showUploaded) {
            let row = cloneDeep(this.historyData[0]) as RecognitionHistory;
            if (row) {
                let data = row.versions[0].data;
                return data;
            }
        }

        return [];
        //let data = row.versions.filter(el => el.version === Number(this.version))[0].data;
        //return data.filter(el => isFiltered(el, this.filter));
    }

    /** получение данных отображаемых в detailsDialog */
    get versionDetailsView(): Array<ModelResponseDTO> {
        console.log("versionDetails");
        let row = cloneDeep(this.historyData.filter(el => el.image === this.image)[0]) as RecognitionHistory;
        let data = row.versions.filter(el => el.version === Number(this.version))[0].data;
        return data.filter(el => isFiltered(el, this.filter));
    }

    /** получение данных отображаемых в historyDetailsDialog */
    get historyDetailsView(): RecognitionHistory {
        console.log("historyDetails");
        let row = cloneDeep(this.historyData.filter(el => el.image === this.image)[0]) as RecognitionHistory;

        for (let version of row.versions) {
            version.data = version.data.filter(el => isFiltered(el, this.filter));
        }

        let frsAll = row.versions.map(version => version.data.map(el => el.frs_name)).flat();
        row.frs = frsAll.filter(onlyUnique);
        return row;
    }

    /** получение данных отображаемых в historyView
     *  проведение сортировки 
     */
    get historyView(): Array<RecognitionHistory> {
        console.log("history");
        let sortedRows = stableSort(this.historyData, comparer(this.order, this.orderBy));;
        return sortedRows;
    }

    setLastResults(data: Array<ModelResponseDTO>) {
        this.lastResults = data;
        let topCandidate = findTopCandidate(data, ((el) => true));
        if (topCandidate) {
            this.topCandidate = topCandidate.name;
        }
    }

    get imageName(): string {
        let name = this.image!;
        let lastDotPosition = name.lastIndexOf(".");
        if (lastDotPosition !== -1) {
            name = name.substr(0, lastDotPosition);
        }
        return name;
    }

    get historyData(): Array<RecognitionHistory> {
        if (this.currentTab == MainViewTabs.TAB_SHARED_IMAGES)
            return this.historyShared;
        else
            return this.history;
    }

    get getVersions(): Array<number> {
        if (this.currentTab == MainViewTabs.TAB_SHARED_IMAGES)
            return this.sharedVersions;
        else {
            return this.versions;
        }
    }

    get isShared(): boolean {
        return (this.currentTab == MainViewTabs.TAB_SHARED_IMAGES);
    }

    setPopupLoading(value: boolean) {
        this.showPopupLoading = value;
    }

    updateShowInner(key: string, geometryStore: GeometryStore) {
        function findResultByKey(history: Array<RecognitionHistory>, key: string): RecognitionResult | undefined {
            for (const r of history) {
                for (const v of r.versions) {
                    for (const f of v.data) {
                        for (const rec of f.results) {
                            if (rec.key === key)
                                return rec;
                            if (rec.inner) {
                                for (const ir of rec.inner.results) {
                                    if ((ir as RecognitionResult).key === key)
                                        return ir;
                                }
                            }
                        }
                    }
                }
            }

            return undefined;
        }


        let result = findResultByKey(this.historyData, key);
        console.log(result)
        if (result) {
            result.showInner = !result.showInner;
            if (result.showInner) {
                geometryStore.setBB(result.bbox[0]);
            }
            else {
                geometryStore.setBB();
            }
        }
    }

    get imagePopupOpened() {
        return this.showImagePopup;
    }

    handleImagePopup(show: boolean) {
        this.showImagePopup = show;
    }

}
