export class CanvasHelper {
  ctx: CanvasRenderingContext2D | undefined = undefined;
  img: HTMLImageElement | undefined = undefined;
  canvasWidth: number = 0;
  canvasHeight: number = 0;
  imgWidth: number = 0;
  imgHeight: number = 0;
  scale: number = 0;
  offsetX: number = 0;
  offsetY: number = 0;
  bb: Array<number> = [];

  constructor() {
  }

  init(ctx: CanvasRenderingContext2D, img: HTMLImageElement) {
    this.ctx = ctx;
    this.img = img;

    this.canvasWidth = ctx.canvas.width;
    this.canvasHeight = ctx.canvas.height;

    this.imgWidth = img.width;
    this.imgHeight = img.height;

    this.scale = Math.min(ctx.canvas.width / img.width, ctx.canvas.height / img.height);

    this.offsetX = (this.canvasWidth / 2) - (this.imgWidth / 2) * this.scale;
    this.offsetY = (this.canvasHeight / 2) - (this.imgHeight / 2) * this.scale;

    //this.bb = [(this.canvasWidth - this.imgWidth) / 2, (this.canvasHeight - this.imgHeight) / 2, this.offsetX + this.imgWidth, this.offsetY + this.imgHeight];
    this.bb = [this.offsetX, this.offsetY, this.offsetX + this.imgWidth * this.scale, this.offsetY + this.imgHeight * this.scale];
    console.log("img bb", this.bb);
  }


  drawImage() {
    if (this.ctx && this.img) {
      this.ctx.drawImage(this.img, this.offsetX, this.offsetY, this.imgWidth * this.scale, this.imgHeight * this.scale);
    }
  }

  transformBBox(bbox: Array<number>) {
    return [this.transformX(bbox[0]), this.transformY(bbox[1]), Math.round(bbox[2] * this.scale), Math.round(bbox[3] * this.scale)];
  }

  transformPolygon(polygon: Array<number>) {
    return polygon.map((v, i) => (i % 2 == 0) ? this.transformX(v) : this.transformY(v));
  }

  /*transformPolygon(polygon: Array<number>) {
    for (let pt of polygon) {
      pt[0] = this.transformX(pt[0]);
      pt[1] = this.transformX(pt[1]);
    }

    return polygon;
  }*/

  transformX(x: number) {
    return Math.round(this.offsetX + x * this.scale);
  }

  transformY(y: number) {
    return Math.round(this.offsetY + y * this.scale);
  }

  isPolygonVisible(p: Array<number>) {
    for (let i = 0; i < p.length / 2; i++) {
      let x = p[i * 2];
      let y = p[i * 2 + 1];
      if (x >= this.bb[0] && x <= this.bb[2] && y >= this.bb[1] && y <= this.bb[3])
        return true;
    }
    return false;
  }
}

/*
export function scaleToFit(ctx: CanvasRenderingContext2D, img: HTMLImageElement) {
  // get the scale
  var scale = Math.min(ctx.canvas.width / img.width, ctx.canvas.height / img.height);
  // get the top left position of the image
  var x = (ctx.canvas.width / 2) - (img.width / 2) * scale;
  var y = (ctx.canvas.height / 2) - (img.height / 2) * scale;
  ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
}

export function drawImage(ctx: CanvasRenderingContext2D, img: HTMLImageElement, x: number = 0, y: number = 0, w: number = ctx.canvas.width, h: number = ctx.canvas.height, offsetX: number = 0.5, offsetY: number = 0.5) {

  if (arguments.length === 2) {
    x = y = 0;
    w = ctx.canvas.width;
    h = ctx.canvas.height;
  }

  // default offset is center
  offsetX = typeof offsetX === "number" ? offsetX : 0.5;
  offsetY = typeof offsetY === "number" ? offsetY : 0.5;

  // keep bounds [0.0, 1.0]
  if (offsetX < 0) offsetX = 0;
  if (offsetY < 0) offsetY = 0;
  if (offsetX > 1) offsetX = 1;
  if (offsetY > 1) offsetY = 1;

  var iw = img.width,
    ih = img.height,
    r = Math.min(w / iw, h / ih),
    nw = iw * r,   // new prop. width
    nh = ih * r,   // new prop. height
    cx, cy, cw, ch, ar = 1;

  // decide which gap to fill
  if (nw < w) ar = w / nw;
  if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
  nw *= ar;
  nh *= ar;

  // calc source rectangle
  cw = iw / (nw / w);
  ch = ih / (nh / h);

  cx = (iw - cw) * offsetX;
  cy = (ih - ch) * offsetY;

  // make sure source rectangle is valid
  if (cx < 0) cx = 0;
  if (cy < 0) cy = 0;
  if (cw > iw) cw = iw;
  if (ch > ih) ch = ih;

  // fill image in dest. rectangle
  ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
}
*/