import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button, Table, TableBody, TableRow, TableCell, TableHead, TableFooter, TablePagination, TextField, Theme, Switch } from '@material-ui/core';
import { timeConverter } from './../../utils'
import i18next from 'i18next';
import { makeAutoObservable, toJS } from "mobx"
import { observer } from "mobx-react"
import mainStore from "./../../stores/mainStore"
import Truncate from 'react-truncate';
import TableHeaders from './tableHeaders'
import FoodImage from './../foodImage'
import { RecognitionHistory } from '../../stores/mainViewStore';
import { ITableOptions } from './../mainView';

const styles = createStyles((theme: Theme) => ({
  tableFilter: {
    textAlign: "center"
  },
  cell: {
    textAlign: "center",
    width: "100%",
    flex: 1
  },
  cellNotes: {
    textAlign: "left",
    cursor: "pointer"
  },
  table: {
    tableLayout: 'fixed',
    userSelect: "none"
  },
  button: {
    maxWidth: 120,
  },
  buttonEdit: {
    maxWidth: 120,
    width: 100
  },
  foodButton: {
    maxWidth: 120,
    width: "100%",
    overflowWrap: "anywhere",
    wordWrap: "break-word",
    wordBreak: "break-word"

  },
  trashImg: {
    width: 16,
    height: 16
  },
  pagination: {
    border: 'none'
  }
}))

export type ColumnDescription = {
  id: string,
  label: string,
  filter: boolean,
  sorted: boolean
}



export interface HistoryTableProps {
  /** ascending/descending order */
  order: 'asc' | 'desc',
  /**  sort property */
  orderBy: string,
  /** array with versions */
  versions: Array<number>,
  /** notes editor open event handler */
  openNotesEditor: (image: string, notes?: string) => void,
  /** remove record dialog open event handler */
  openRemoveDialog: (record: RecognitionHistory) => void,

  rows: Array<RecognitionHistory>,

  /** настройки таблицы */
  options: ITableOptions,

  classes: any
}

interface Filter {
  [key: string]: string,
  tag: string,
  notes: string
}

class HistoryTableState {

  filter: Filter = {
    tag: "",
    notes: ""
  };
  page: number = 0;
  rowsPerPage: number = 6;

  constructor() {
    makeAutoObservable(this);
  }

  updateFilter(filter: Filter) {
    this.filter = filter;
  }

  updatePage(page: number) {
    this.page = page;
  }

}

/**
 * Таблица для показа данных распознавания
 */
const HistoryTable = observer(
  class HistoryTable extends React.Component<HistoryTableProps> {

    state = new HistoryTableState()
    store = mainStore.mainViewStore

    filteredData: RecognitionHistory[] = []

    NColumn = { id: "number", label: "№", filter: false, sorted: false }
    TimeStampColumn = { id: "timestamp", label: i18next.t('upload_date'), filter: false, sorted: true }
    DeleteColumn = { id: "delete_icon", label: "", filter: false, sorted: false }
    TagColumn = { id: "tag", label: i18next.t('user_tag'), filter: true, sorted: true }
    NotesColumn = { id: "notes", label: i18next.t('notes'), filter: true, sorted: true }
    ImageColumn = { id: "image", label: i18next.t('image'), filter: false, sorted: false }
    DetailsColumn = { id: "details", label: "", filter: false, sorted: false }
    ShareColumn = { id: "share", label: i18next.t('share'), filter: false, sorted: false }

    tableHeaders: Array<ColumnDescription> = [
      /*{ id: "number", label: "№", filter: false, sorted: false },
      { id: "timestamp", label: i18next.t('upload_date'), filter: false, sorted: true },
      { id: "delete_icon", label: "", filter: false, sorted: false },
      { id: "tag", label: i18next.t('user_tag'), filter: true, sorted: true },
      { id: "notes", label: i18next.t('notes'), filter: true, sorted: true },
      { id: "image", label: i18next.t('image'), filter: false, sorted: false },
      { id: "details", label: "", filter: false, sorted: false },
      { id:"share", label: i18next.t('share'), filter:false, sorted:false}*/
    ];

    constructor(props: HistoryTableProps) {
      super(props);
      this.filterData = this.filterData.bind(this);

      this.tableHeaders.push(this.NColumn);
      this.tableHeaders.push(this.TimeStampColumn);
      if (props.options.deleteRecord)
        this.tableHeaders.push(this.DeleteColumn);
      this.tableHeaders.push(this.TagColumn);
      this.tableHeaders.push(this.NotesColumn);
      this.tableHeaders.push(this.ImageColumn);
      this.tableHeaders.push(this.DetailsColumn);
      if (props.options.shareRecord)
        this.tableHeaders.push(this.ShareColumn);
    }

    handleFilter = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let filter = toJS(this.state.filter);
      filter[name] = event.target.value;
      this.state.updateFilter(filter);
    };

    handleRequestSort = (property: string) => {
      this.store.updateOrder(property);
    };

    renderTableFilters(headers: Array<ColumnDescription>) {
      console.log("renderTableFilters");
      let classes = this.props.classes;
      //let filter = toJS(this.state.filter);
      return (
        headers.map(cell => (
          <TableCell key={cell.id} className={classes.tableFilter}>
            {cell.filter &&
              <TextField
                label={i18next.t('filter')}
                value={this.state.filter[cell.id]}
                onChange={this.handleFilter(cell.id)}
                margin="normal"
                variant="outlined"
              />
            }
          </TableCell>
        ))
      )
    }

    filterData(el: any) {
      const filtered = ["tag", "notes"];
      for (let name of filtered) {
        if (this.state.filter[name] !== "") {
          let val = (typeof el[name] !== "undefined") ? el[name] : "";
          if (val.toLowerCase().indexOf(this.state.filter[name].toLowerCase()) === -1)
            return false;
        }
      }

      return true;
    }

    openVersionDetails(row: RecognitionHistory, version: number) {
      this.createNamesSnapshot();
      let current = row.versions.filter(el => el.version === version)[0];
      this.store.openVersionDetailsDialog(version, row.image, current.topCandidate);
    }

    openHistoryDetails(row: RecognitionHistory) {
      this.createNamesSnapshot();
      this.store.openHistoryDetailsDialog(row.image);
    }

    createNamesSnapshot() {
      let images = this.filteredData.map(el => el.image);
      this.store.setImages(images);
    }

    //handles when tag lost focus
    handleTag = (row: RecognitionHistory) => (event: React.FocusEvent<HTMLInputElement>) => {
      //console.log(row,event.target.value);
      let newValue = event.target.value;
      let oldValue = row.tag + "";
      if (oldValue !== newValue) {
        this.store.updateTag(row.image, newValue);
      }
    };

    handleShare = (row: RecognitionHistory, checked: boolean) => {
      if (checked) {
        this.store.updateShare(row.image);
      }
    }

    renderCell(record: RecognitionHistory, version: number) {
      let classes = { ...this.props.classes };
      let curVersion = record.versions.filter(el => el.version === version);
      if (curVersion.length === 0 || curVersion[0].data.length === 0) {
        return (
          <TableCell key={version} className={classes.cell} />
        )
      } else {
        return (
          <TableCell key={version} className={classes.cell}>
            <Button className={classes.foodButton} size="small" onClick={(e) => this.openVersionDetails(record, version)}>
              {curVersion.map(el => el.topCandidate)}
            </Button>
          </TableCell>
        )
      }
    }

    editNotes(record: RecognitionHistory) {
      //console.log("edit notes clicked");
      //this.state.openNotesEditor(record.notes,record.image);
      this.props.openNotesEditor(record.image, record.notes);
    }

    splitText(text: string | undefined) {
      if (text) {
        return text.split('\n').map((str, i, arr) => {
          const line = <span key={i}>{str}</span>;
          if (i === arr.length - 1) {
            return line;
          } else {
            return [line, <br key={i + 'br'} />];
          }
        })
      }
    }

    render() {
      //trace(true);
      console.log("render historyTable");
      let classes = { ...this.props.classes };

      //add dynamic versions columns
      let headers = [...this.tableHeaders];
      this.props.versions.forEach((el, index) => {
        headers.push({
          id: `version_${el}`,
          label: i18next.t('version') + el,
          filter: false,
          sorted: true
        });
      });

      //let rows = this.store.historyView;
      this.filteredData = this.props.rows.filter(this.filterData);
      const elemsCount = this.filteredData.length;
      //const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, elemsCount - this.state.page * this.state.rowsPerPage);
      if (elemsCount < this.state.page * this.state.rowsPerPage) {
        let newPage = Math.floor(this.filteredData.length / this.state.rowsPerPage);
        this.state.updatePage(newPage);
      }

      return (
        <Table size="small" className={classes.table} >
          <colgroup>
            <col style={{ width: '50px' }} />
            <col style={{ width: "130px" }} />
            {this.props.options.deleteRecord &&
              <col style={{ width: "50px" }} />}
            <col style={{ width: "200px" }} />
            <col style={{ width: "200px" }} />
            <col style={{ width: '200px' }} />
            <col style={{ width: '120px' }} />
            {this.props.options.shareRecord &&
              <col style={{ width: '100px' }} />}
            {this.props.versions.map((version) => {
              return (
                <col key={version} style={{ width: "200px" }} />
              )
            })
            }
          </colgroup>
          <TableHead>
            <TableRow>
              <TableHeaders headers={headers} order={this.props.order} orderBy={this.props.orderBy} sort={this.handleRequestSort} />
            </TableRow>
            <TableRow>
              {this.renderTableFilters(this.tableHeaders)}
              {this.props.versions.map((version) => {
                return (
                  <TableCell key={version} />
                )
              })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.filteredData.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage).map((record, n) => {
                //filteredData.map((record , n ) => {
                let time = timeConverter(record.timestamp);
                return (
                  <TableRow key={record.image} >
                    <TableCell className={classes.cell}>{this.state.page * this.state.rowsPerPage + n + 1}</TableCell>
                    <TableCell className={classes.cell}> {time} </TableCell>
                    {this.props.options.deleteRecord &&
                      <TableCell>
                        <img src={process.env.PUBLIC_URL + '/images/trashWhite.svg'} alt="/images/trashWhite.svg" className={classes.trashImg} onClick={(e) => { this.props.openRemoveDialog(record) }} />
                      </TableCell>}
                    <TableCell className={classes.cell}>
                      <TextField
                        defaultValue={record.tag}
                        //onChange={this.handleTag(record)}
                        onBlur={this.handleTag(record)}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: !this.props.options.editTag
                        }}
                      />
                    </TableCell>
                    {(record.notes || !this.props.options.editNotes) ?
                      <TableCell className={this.props.options.editNotes ? classes.cellNotes : classes.cell}
                        onClick={(e) => this.props.options.editNotes && this.editNotes(record)}
                      >
                        <Truncate lines={3} /*width={160}*/ >  {this.splitText(record.notes)} </Truncate>
                      </TableCell>
                      :
                      <TableCell className={classes.cell} onClick={(e) => this.editNotes(record)}>
                        <Button variant="contained" className={classes.buttonEdit}> {i18next.t('edit')}  </Button>
                      </TableCell>
                    }

                    <TableCell className={classes.cell}>
                      <FoodImage image={record.image} alias={""} width={150} height={100} thumbnail={true} shared={this.props.options.downloadShared} />
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" className={classes.button} onClick={(e) => this.openHistoryDetails(record)}> {i18next.t('details')}  </Button>
                    </TableCell>
                    {this.props.options.shareRecord &&
                      <TableCell>
                        <Switch
                          checked={record.shared || false}
                          onChange={(e, checked) => this.handleShare(record, checked)}
                        />
                      </TableCell>}
                    {this.props.versions.map((version) => {
                      return this.renderCell(record, version);
                    })}
                  </TableRow>
                )
              })
              //})              
            }
            {/*emptyRows > 0 && (
                <TableRow style={{ height: 122 * emptyRows,width : "100%"}}>
                  <TableCell style={{boder:'none'}} />
                </TableRow>
            )*/}
          </TableBody>
          {elemsCount >= this.state.rowsPerPage &&
            <TableFooter>
              <TableRow>
                <TablePagination
                  classes={{
                    root: classes.pagination
                  }}
                  rowsPerPageOptions={[this.state.rowsPerPage]}
                  colSpan={8}
                  count={elemsCount}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={(e, page) => this.state.updatePage(page)}
                />
              </TableRow>
            </TableFooter>}
        </Table>
      )
    }
  })

export default withStyles(styles)(HistoryTable);




