import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableRow, TableCell, TableHead, Theme, Button } from '@material-ui/core';
import mainStore from "./../stores/mainStore"
import i18next from 'i18next';
import { observer } from "mobx-react"
import { RecognitionHistory, BRSVersionResponse } from './../stores/mainViewStore'
import { RecognitionResultDTO } from './../dto/fpsDTO'

const styles = (theme: Theme) => createStyles(({
  tableHeader: {
    textAlign: "center"
  },
  noBorderCell: {
    borderBottomWidth: 0
  },
  frsCell: {
    borderRightWidth: '2px',
    borderRightStyle: 'solid',
    borderRightColor: "initial",
  },
  table: {
    //userSelect : "none"
  }
}))



interface VersionDetailsTableProps {
  /** recognition results */
  record: RecognitionHistory,
  /** callback to call when switching version mode */
  versionSelected: (version: number, topCandidate: string, topFRS: string) => void
  classes: any
}

/** таблица с отображением данных для всех версий */
const VersionsDetailTable = observer((props: VersionDetailsTableProps) => {
  let classes = { ...props.classes };

  const rowStyle = (drawBold: boolean): React.CSSProperties => {
    return {
      height: '50px',
      width: '100%',
      borderTopWidth: drawBold ? '2px' : '1px',
      borderTopStyle: drawBold ? 'solid' : 'none',
      borderTopColor: "initial",
      //background : color
    };
  }

  const displayName = (row: RecognitionResultDTO) => {
    if (row.presents !== undefined) {
      if (row.presents === true)
        return `${row.name} (TRUE)`;
      else
        return `${row.name} (FALSE)`;
    }

    return row.name
  }


  const renderCell = (frs: string, cell: BRSVersionResponse) => {
    let classes = { ...props.classes };
    let data = cell.data.filter((el: any) => el.frs_name === frs);
    if (data.length === 0) {
      return (
        <TableCell key={cell.version} />
      )
    }
    let row = data[0];
    return (
      <TableCell key={cell.version} /*style={{background : color}}*/>
        <Table size="small">
          <TableBody>
            {row.results.map((el, n) => {
              //let textColor = (el.name === cell.topCandidate) ? "blue" : "black";
              let textColor = (el.presents === undefined && n === 0 || el.presents === true) ? "blue" : "black";
              return (
                <TableRow key={n} >
                  <TableCell className={classes.noBorderCell} style={{/*height:30,*/color: textColor }}>{displayName(el)/*el.name*/}</TableCell>
                  <TableCell className={classes.noBorderCell} style={{ color: textColor, textAlign: "right" }}>{el.confidency}</TableCell>
                </TableRow>
              )
            }
            )}
          </TableBody>
        </Table>
      </TableCell>
    )
  }

  /** показ detailsDialog для выбранной версии */
  const showDetails = (version: number, topCandidate: string, topFRS: string) => {
    // mainStore.mainViewStore.switchVersion(version,topCandidate,topFRS);
    props.versionSelected(version, topCandidate, topFRS);
  }


  return (
    <Table size="small" className={classes.table} >
      <colgroup>
        <col style={{ width: '15%' }} />
        {props.record.versions.map((el, n) => {
          return (
            <col key={el.version} style={{ width: 'auto', minWidth: 100, maxWidth: 300 }} />
          )
        })
        }
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell>
            {i18next.t('FRS_Version')}
          </TableCell>
          {
            props.record.versions.map((el, n) => {
              return (
                <TableCell key={el.version} className={classes.tableHeader} >
                  <Button className={classes.foodButton} size="small" onClick={(e) => showDetails(el.version, el.topCandidate, el.topFRS)}>
                    {el.version}
                  </Button>
                </TableCell>
              )
            })
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          props.record.frs.map((frs, n) => {
            let drawBold = n > 0;
            return (
              <TableRow key={frs} style={rowStyle(drawBold)}>
                <TableCell className={classes.frsCell}> {frs} </TableCell>
                {props.record.versions.map((row, index) => {
                  return renderCell(frs, row)
                })
                }
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
  )
})


export default withStyles(styles)(VersionsDetailTable);





