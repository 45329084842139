import React, { useRef, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import foodRecognitionAPI from './../recognitionAPI'
import { isChrome, createBB } from './../utils'
import { Theme } from '@material-ui/core';
import { CanvasHelper } from 'canvas';
import GeometryStore, { AnnotationGeometry, HighlightItem } from './../stores/geometryStore';
import mainStore from "./../stores/mainStore"
import { CircularProgress } from '@material-ui/core';
import i18next from 'i18next';

//https://bugs.chromium.org/p/chromium/issues/detail?id=1082669  chrome issue
const imageFit: "contain" | "cover" = isChrome ? "contain" : "cover";

const useStyles = makeStyles((theme: Theme) => createStyles({
  img: {
    objectFit: imageFit,
    //margin : 2,
  },
  progress: {
    position: "absolute",
    //top : 100,
    //left : 100
  }
}))

interface CanvasImageProps {
  /**  image file on server or data url */
  image: string,
  /** image width */
  width: number,
  /** image height */
  height: number,
  /** annotations geometry */
  geometry?: Array<AnnotationGeometry>
  /** highlight item */
  highlight?: HighlightItem
  /** callback to call when image is loaded to display geometry */
  imageLoaded?: () => void
  /** geometry store */
  store: GeometryStore
}

export interface FoodImageProps {
  /**  image file on server or data url */
  image: string,
  /** download file from server? */
  download?: boolean,
  /**  image alias */
  alias?: string,
  /** image width */
  width: number,
  /** image height */
  height: number,
  /** use thumbnail */
  thumbnail: boolean,
  /** use html img or draw on canvas */
  useCanvas: boolean,
  /** download from share catalog? */
  shared: boolean
  /** annotations geometry */
  geometry?: Array<AnnotationGeometry>
  /** highlight item */
  highlight?: HighlightItem
  /** callback to call when image is loaded to display geometry */
  imageLoaded?: () => void
  /** geometry store */
  store?: GeometryStore
  //classes : any
}

interface Size {
  width: number,
  height: number
}

interface Fragment {
  x: number,
  y: number,
  data: ImageData
}

const CanvasImage = (props: CanvasImageProps) => {

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [fragmentsArr, setFragmentsArr] = useState<Array<Fragment>>([]);

  //const [imgSize, setImgSize] = useState<Size>();

  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [showError, setShowError] = useState<boolean>(false);

  const [isCanvasRefreshing, setCanvasRefreshing] = useState<boolean>(false);

  const lineWidth = 3;

  const bboxColor = "red";
  const polygonColor = "#425A69";

  const highlightBBoxColor = "green";
  const highlightPolygonColor = "blue";

  //const store = mainStore.geometryStore;
  const store = props.store;

  useEffect(() => {
    setFragmentsArr([]);
    store.updateGeometry([]);
    updateCanvas();
    return function cleanUp() {
      console.log("foodImage unmounted");
      store.updateGeometry([]);
    }
  }, [props.image]);

  useEffect(() => {
    console.log("geometry changed")
    if (canvasRef.current) {
      let canvas = canvasRef.current;
      let context = canvas.getContext("2d");
      if (context !== null) {
        restoreCanvas(context);
        if (props.geometry && props.geometry.length > 0) {
          setFragmentsArr([]);
          storeImageData(context);
        }
      }
    }
  }, [props.geometry]);

  useEffect(() => {
    if (canvasRef.current /*&& !isCanvasRefreshing*/) {
      setCanvasRefreshing(true);
      let canvas = canvasRef.current;
      let context = canvas.getContext("2d");
      if (context !== null) {
        restoreCanvas(context);
      }
      //delay before drawing geometry to ensure that canvas is restored
      setTimeout(function () {
        if (props.geometry && props.geometry.length > 0) {
          if (context !== null) {
            updateGeometry(context, props.geometry);
          }
        }
        setCanvasRefreshing(false);
      }, 0);
    }
  }, [fragmentsArr, props.highlight])


  const storeFragment = (context: CanvasRenderingContext2D, bbox: Array<number>) => {
    //console.log("storeFragment", bbox);
    setFragmentsArr(arr => arr.concat({
      x: bbox[0],
      y: bbox[1],
      data: context.getImageData(bbox[0] - lineWidth, bbox[1] - lineWidth, bbox[2] + 3 * lineWidth, bbox[3] + 3 * lineWidth)
    }));
  }

  const restoreCanvas = (context: CanvasRenderingContext2D) => {
    for (let i = 0; i < fragmentsArr.length; i++) {
      context.putImageData(fragmentsArr[i].data, fragmentsArr[i].x - lineWidth, fragmentsArr[i].y - lineWidth);
    }
  }

  const storeImageData = (context: CanvasRenderingContext2D) => {
    if (props.geometry) {
      for (let i = 0; i < props.geometry.length; i++) {
        let g = props.geometry[i];
        if (g.bbox) {
          g.bbox.forEach(bb => {
            let bbox = store.helper.transformBBox(bb);
            storeFragment(context, bbox);
          });
        } else if (g.polygons) {
          g.polygons.forEach(polygon => {
            let bbox = createBB(polygon);
            //console.log(bbox);
            bbox = store.helper.transformBBox(bbox);
            storeFragment(context, bbox);
          });
        }
      }
    }
  }

  const drawGeometry = (context: CanvasRenderingContext2D, geometry: AnnotationGeometry) => {
    console.log("drawGeometry");
    context.save();
    let isHighlighted = store.isHighlighted(geometry.key);
    if (geometry.bbox) {
      context.lineWidth = lineWidth;
      context.strokeStyle = isHighlighted ? highlightBBoxColor : bboxColor;
      //console.log("draw bb",geometry.bbox);
      geometry.bbox.forEach(bb => {
        let bbox = store.helper.transformBBox(bb);
        context.strokeRect(bbox[0], bbox[1], bbox[2], bbox[3]);
      });
    }

    if (geometry.polygons) {
      geometry.polygons.forEach(polygon => {
        console.log("draw polygon", polygon, isHighlighted);
        context.globalAlpha = isHighlighted ? 0.55 : 0.25;
        context.fillStyle = '#00f';
        context.lineWidth = lineWidth;
        context.strokeStyle = polygonColor;
        context.strokeStyle = isHighlighted ? highlightPolygonColor : polygonColor;
        let p = store.helper.transformPolygon(polygon);
        if (store.helper.isPolygonVisible(p)) {
          context.beginPath();
          context.moveTo(p[0], p[1]);
          for (let i = 1; i < p.length / 2; i++) {
            context.lineTo(p[i * 2], p[i * 2 + 1]);
          }
          //context.lineTo(p[0], p[1]);
          context.closePath();
          context.stroke();
          context.fill();
        }
      });
    }
    context.restore();
  }

  const updateCanvas = () => {
    if (canvasRef.current) {
      console.log("update canvas");
      let canvas = canvasRef.current;
      let context = canvas.getContext("2d");
      canvas.addEventListener("mousemove", (e) => {
        store.setCursorCoord([e.offsetX, e.offsetY]);
      }, false);
      //let img = new Image();
      let img = store.image;
      setShowProgress(true);
      img.onload = function (ev: Event) {
        console.log("image loaded", img.width, img.height);
        setShowProgress(false);
        if (context !== null) {
          /*setImgSize({
            width: img.width,
            height: img.height
          });*/
          store.helper.init(context, img);
          context.clearRect(0, 0, canvas.width, canvas.height);
          store.helper.drawImage();
          console.log("image has been draw");
        }
        if (props.imageLoaded) {
          props.imageLoaded();
        }
      }
      img.onerror = function (errorMsg: Event | string) {
        setShowProgress(false);
        setShowError(true);
      }
      img.src = props.image;
    }
  }


  const updateGeometry = (context: CanvasRenderingContext2D, geometry: Array<AnnotationGeometry>) => {
    console.log("update geometry", geometry);
    for (let i = 0; i < geometry.length; i++) {
      if (geometry[i].display) {
        drawGeometry(context, geometry[i]);
      }
    }
  }

  let classes = useStyles();
  return (
    <>
      <canvas ref={canvasRef} width={props.width} height={props.height} />
      {showProgress &&
        <CircularProgress className={classes.progress} style={{ top: props.height / 2, left: props.width / 2 }} />
      }
      {showError &&
        <div style={{ top: props.height / 2, left: props.width / 2, color: "red", position: "absolute" }}>
          {i18next.t('img_loading_error')}
        </div>
      }
    </>
  )
}

const download = (image: string, thumbnail: boolean, shared: boolean) => {
  return thumbnail ? foodRecognitionAPI.thumbnail(image, shared) : foodRecognitionAPI.download(image, shared);
}

/** Image control */
const FoodImage = (props: FoodImageProps) => {
  let classes = useStyles();
  return (
    props.useCanvas ?
      <CanvasImage image={props.download ? download(props.image, props.thumbnail, props.shared) : props.image} width={props.width} height={props.height} geometry={props.geometry} highlight={props.highlight} imageLoaded={props.imageLoaded} store={props.store!} />
      :
      <img className={classes.img} src={props.download ? download(props.image, props.thumbnail, props.shared) : props.image} alt={props.alias} style={{ width: props.width, height: props.height }} />
  )
}

FoodImage.defaultProps = {
  download: true,
  thumbnail: false,
  useCanvas: false
}




export default FoodImage;