import { ModelResponseDTO } from "./dto/fpsDTO";

export function formatedDate(date: Date) {
  if (date && date.getDate) {
    var dd: string | number = date.getDate();
    var mm: string | number = date.getMonth() + 1; //January is 0!

    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    return yyyy + '-' + mm + '-' + dd;
  }

  return date;
}

export function timeConverter(UNIX_timestamp: string) {
  //var a = new Date(UNIX_timestamp * 1000);
  var a = new Date(UNIX_timestamp);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
    'Oct', 'Nov', 'Dec'
  ];

  let locale = 'en-US';
  var year = a.getFullYear().toLocaleString(locale, { minimumIntegerDigits: 4, useGrouping: false });
  var month = months[a.getMonth()];
  var date = a.getDate().toLocaleString(locale, { minimumIntegerDigits: 2, useGrouping: false });
  var hour = a.getHours().toLocaleString(locale, { minimumIntegerDigits: 2, useGrouping: false });
  var min = a.getMinutes().toLocaleString(locale, { minimumIntegerDigits: 2, useGrouping: false });
  var sec = a.getSeconds().toLocaleString(locale, { minimumIntegerDigits: 2, useGrouping: false });
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  return time;
}

export function dateComparer(date1: Date, date2: Date) {
  return (date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear());
}


export function hashCode(s: string) {
  var hash = 0, i, chr;
  if (s != null && s !== undefined && s.length !== undefined) {
    for (i = 0; i < s.length; i++) {
      chr = s.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
  }
  return hash;
};

export function onlyUnique<T>(value: T, index: number, self: Array<T>) {
  return self.indexOf(value) === index;
}

export interface TopCandidateInfo {
  /** название блюда */
  name: string,
  /** оценка распознавания */
  confidency: string,
  /** frs */
  frs_name: string
}


export function findTopCandidate(data: ReadonlyArray<ModelResponseDTO>, isFiltered: (data: ModelResponseDTO) => boolean): TopCandidateInfo {
  let maxScore = 0;
  let candidateEl = { name: "", confidency: "", frs_name: "" };
  if (data && data.length && data.length > 0) {
    for (const rec of data) {
      if (rec.results.length > 0 && isFiltered(rec)) {
        //if (rec.results[0].presents === undefined) {
        let score = rec.results[0].confidency;
        if (Number(score) > Number(maxScore)) {
          //candidate = rec.results[0].name;
          candidateEl.name = rec.results[0].name;
          candidateEl.confidency = rec.results[0].confidency;
          candidateEl.frs_name = rec.frs_name;
          maxScore = Number(score);
        }
        //}
      }
    }
  }
  return candidateEl;
}

export function pointIsInPoly(p: Array<number>, polygon: Array<number>) {
  var isInside = false;
  var minX = polygon[0], maxX = polygon[0];
  var minY = polygon[1], maxY = polygon[1];
  for (var n = 2; n < polygon.length; n += 2) {
    minX = Math.min(polygon[n], minX);
    maxX = Math.max(polygon[n], maxX);
    minY = Math.min(polygon[n + 1], minY);
    maxY = Math.max(polygon[n + 1], maxY);
  }

  if (p[0] < minX || p[0] > maxX || p[1] < minY || p[1] > maxY) {
    return false;
  }

  for (let i = 0, j = polygon.length - 2; i < polygon.length;) {
    if ((polygon[i + 1] > p[1]) != (polygon[j + 1] > p[1]) &&
      p[0] < (polygon[j] - polygon[i]) * (p[1] - polygon[i + 1]) / (polygon[j + 1] - polygon[i + 1]) + polygon[i]) {
      isInside = !isInside;
    }
    j = i;
    i = i + 2;
  }

  return isInside;
}

export function createBB(polygon: Array<number>) {
  let bb: Array<number> = [];
  if (polygon) {
    let left = polygon[0];
    let right = polygon[0];
    let top = polygon[1];
    let bottom = polygon[1];

    for (let i = 2; i < polygon.length; i += 2) {
      if (polygon[i] < left)
        left = polygon[i];
      if (polygon[i] > right)
        right = polygon[i];
      if (polygon[i + 1] < top)
        top = polygon[i + 1];
      if (polygon[i + 1] > bottom)
        bottom = polygon[i + 1];
    }

    bb = [left, top, right - left, bottom - top];
  }

  return bb;
}

function checkBrowser() {

  let navUserAgent = navigator.userAgent;
  let browserName = navigator.appName;
  let browserVersion = '' + parseFloat(navigator.appVersion);
  let majorVersion = parseInt(navigator.appVersion, 10);
  let tempNameOffset, tempVersionOffset, tempVersion;


  if ((tempVersionOffset = navUserAgent.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    browserVersion = navUserAgent.substring(tempVersionOffset + 6);
    if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1)
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if ((tempVersionOffset = navUserAgent.indexOf("MSIE")) !== -1) {
    browserName = "IE";
    browserVersion = navUserAgent.substring(tempVersionOffset + 5);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
    if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1)
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if ((tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) < (tempVersionOffset = navUserAgent.lastIndexOf('/'))) {
    browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
    browserVersion = navUserAgent.substring(tempVersionOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  // trim version
  if ((tempVersion = browserVersion.indexOf(";")) !== -1)
    browserVersion = browserVersion.substring(0, tempVersion);
  if ((tempVersion = browserVersion.indexOf(" ")) !== -1)
    browserVersion = browserVersion.substring(0, tempVersion);

  return browserName;
}

export const isChrome = (checkBrowser() === "Chrome");
export const isIE = (checkBrowser() === "IE");

export function generateQuickGuid() {
  return Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
}

export function shiftPolygon(origin: Array<number> | undefined, polygons: Array<Array<number>>) {
  if (origin && polygons) {
    let x = origin[0];
    let y = origin[1];
    for (let poly of polygons) {
      for (let i = 0; i < poly.length / 2; i++) {
        poly[i * 2] = x + poly[i * 2];
        poly[i * 2 + 1] = y + poly[i * 2 + 1];
      }
    }
  }
  return polygons;
}