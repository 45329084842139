import {RecognitionHistory} from './stores/mainViewStore';

export type SortOrder = "asc" | "desc";


function cmp(a : any, b : any, orderBy : string) {
  if (typeof(a[orderBy]) === 'boolean') {
    if (a[orderBy] < b[orderBy])
      return -1;
    if (a[orderBy] > b[orderBy])
      return 1;

    return 0;
  }

  if ((b[orderBy] || "") < (a[orderBy] || "")) {
    return -1;
  }
  if ((b[orderBy] || "") > (a[orderBy] || "")) {
    return 1;
  }
  return 0;
}

function cmpConfidency(a : RecognitionHistory,b : RecognitionHistory,orderBy : string) {
  let [version,number] = orderBy.split("_");
  let _a = a.versions.filter(el=>el.version === Number(number));
  let _b = b.versions.filter(el=>el.version === Number(number));

  if (_a.length === 0  && _b.length === 0)
    return 0;   //no versions
  if (_a.length === 0)
    return 1;
  if (_b.length === 0)
    return -1;

  if  (_a[0].topConfidency > _b[0].topConfidency) 
    return -1;

  if  (_a[0].topConfidency < _b[0].topConfidency) 
    return 1;

  return 0;
}


function getSorting(order : SortOrder, orderBy : string,comparer : (a : any,b:any,orderBy:string) => number) {
  return order === 'desc' ? (a : any, b : any) => comparer(a, b, orderBy) : (a : any, b : any) => -comparer(a, b, orderBy);
}

type Comparer<T> = (a : T, b : T) =>  number

export function comparer(order : SortOrder,orderBy : string) {
  if (orderBy.startsWith("version")) 
    return getSorting(order,orderBy,cmpConfidency);
  else 
    return getSorting(order,orderBy,cmp);
  
}



export function stableSort<T>(array : Array<T>, cmp : Comparer<T>) : Array<T> {
  const stabilizedThis = array.map((el : T, index : number) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0] as T, b[0] as T);
    if (order !== 0) return order;
    return (a[1] as number) - (b[1] as number);
  });
  return stabilizedThis.map((el : any) => el[0]);
}


