import React, { useState, useEffect } from 'react'
import i18next from 'i18next';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, TextField, Theme, Grid } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  popup: {
    marginRight: 100,
    width: "400px"
  },
  inputField: {
    width: "350px",
    marginBottom: "50px"
  },
  content: {
    textAlign: "center",
  },
  button: {
    width: "150px",
    marginBottom: "20px"
  }
});


export interface InputDialogProps {
  /** is dialog opened */
  open: boolean
  /**  Close event handler */
  onClose: (text: string, cancel: boolean) => void,
  /** dialog title */
  title?: string,
  /** input text */
  inputText: string,
  classes: any
}

interface DialogWindowState {
  open: boolean
}

/**
 *  Popup dialog with buttons
 */
const InputDialog = (props: InputDialogProps) => {

  const [inputText, setInputText] = useState<string>(props.inputText);

  const handleInputText = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  }

  const classes = { ...props.classes };

  return (
    <Dialog
      open={props.open}
      //onClose={()=>{this.props.onClose(false)}}
      aria-labelledby="alert-dialog-slide-title"
      //aria-describedby="alert-dialog-slide-description"
      //maxWidth="md"
      classes={{
        paper: classes.popup
      }}
    >
      <DialogTitle id="alert-dialog-slide-title">{props.title || ""}</DialogTitle>
      <div className={classes.content}>
        <TextField
          defaultValue={props.inputText}
          onChange={handleInputText()}
          margin="normal"
          variant="outlined"
          multiline={false}
          className={classes.inputField}
        />
        <Grid container direction="row" justify="space-around">
          <Button variant="contained" onClick={() => { props.onClose(inputText, false) }} color="primary" className={classes.button}>
            {i18next.t('OK')}
          </Button>
          <Button variant="contained" color="secondary" className={classes.button} onClick={() => props.onClose("", true)}>
            {i18next.t('cancel')}
          </Button>
        </Grid>
      </div>
    </Dialog>
  )
}

export default withStyles(styles)(InputDialog);