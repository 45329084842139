import { makeAutoObservable } from "mobx"
import i18next from 'i18next';
import Cookies from 'universal-cookie';
import mainStore from "./../stores/mainStore"

export enum AuthenticateStatus {
    Unknown = 0,
    Aunhenticated = 1,
    NotAuthenticated = 2
}

class UIState {
    /** язык */
    language: string = "en"
    /** кука для хранения user_id */
    cookieId: string = "FRApp"
    cookies = new Cookies()
    /** статус аутентификации */
    authenticated: number = AuthenticateStatus.Unknown
    token: string = ""

    constructor() {
        makeAutoObservable(this, { cookieId: false, cookies: false })
    }

    /** изменение языка */
    changeLanguage(language: string) {
        this.language = language;
        i18next.changeLanguage(language);
    }

    /** инициализация */
    init() {
        i18next.init({
            lng: this.language,
            resources: require("./../locale/" + this.language + ".json")
        });
    }

    /** инициализация статуса аутентификации */
    authenticate() {
        if (this.cookies.get(this.cookieId) !== undefined) {
            this.authenticated = AuthenticateStatus.Aunhenticated;
        } else {
            this.authenticated = AuthenticateStatus.NotAuthenticated;
        }
    }

    /** получение идентификатора пользователя */
    get user() {
        if (this.authenticated && this.cookies.get(this.cookieId))
            return this.cookies.get(this.cookieId);
        return "";
    }

    logIn(login: string) {
        this.cookies.set(this.cookieId, login, { path: '/' });
        this.authenticated = AuthenticateStatus.Aunhenticated;
    }

    logOut() {
        mainStore.logout();
        this.cookies.remove(this.cookieId, { path: '/' });
        this.authenticated = AuthenticateStatus.NotAuthenticated;
    }

    setToken(token: string) {
        this.token = token;
    }

}

const uiState = new UIState();


export default uiState;