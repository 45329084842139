import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeAutoObservable } from "mobx"
import DialogWindow from "./../dialogWindow"
import { observer } from "mobx-react"
import mainStore from "./../../stores/mainStore"
import foodRecognitionAPI from './../../recognitionAPI'
import HistoryTable from './historyTable'
import i18next from 'i18next';
import NotesEditor from './../notesEditor'
import { Theme } from '@material-ui/core'
import { RecognitionHistory } from '../../stores/mainViewStore';
import { ITableOptions } from './../mainView';

const styles = (theme: Theme) => ({
})

export interface HistoryViewProps {
  /** ascending/descending order */
  order: 'asc' | 'desc',
  /**  sort property */
  orderBy: string,
  /** array with versions */
  versions: Array<number>,
  /** настройки таблицы */
  options: ITableOptions
}

class HistoryViewState {

  notesEditorOpened: boolean = false
  notes?: string = undefined
  image?: string = undefined
  dialogOpened: boolean = false
  imageRemoved?: string = undefined

  constructor() {
    makeAutoObservable(this);
  }

  openRemoveDialog(record: RecognitionHistory) {
    this.dialogOpened = true;
    this.imageRemoved = record.image;
  }

  closeRemoveDialog() {
    this.dialogOpened = false;
    this.imageRemoved = undefined;
  }

  openNotesEditor(image: string, notes?: string) {
    this.notesEditorOpened = true;
    this.notes = notes;
    this.image = image;
  }

  closeNotesEditor() {
    this.notesEditorOpened = false;
  }
}

/**  View component with history recognition information */
const HistoryView = observer(
  class HistoryView extends React.Component<HistoryViewProps> {

    state = new HistoryViewState()
    store = mainStore.mainViewStore

    constructor(props: HistoryViewProps) {
      super(props);
      this.openNotesEditor = this.openNotesEditor.bind(this);
      this.onNotesEditorClosed = this.onNotesEditorClosed.bind(this);
      this.openRemoveDialog = this.openRemoveDialog.bind(this);
    }

    openNotesEditor(image: string, notes?: string) {
      this.state.openNotesEditor(image, notes);
    }


    onNotesEditorClosed(bSave: boolean, notes?: string) {
      console.log("edit notes closed", bSave, notes);
      if (bSave) {
        this.store.updateNotes(this.state.image!, notes);
      }
      this.state.closeNotesEditor();
    }

    openRemoveDialog(record: RecognitionHistory) {
      this.state.openRemoveDialog(record);
    }

    /** удаление картинки из базы */
    onRemoveDbDialogClosed(action: number) {
      if (action === 1) { //remove
        let image = this.state.imageRemoved!;
        foodRecognitionAPI.removeImage(image).then(result => {
          //this.store.fetchHistory();          
          this.store.removeImage(image);
        });
      }
      this.state.closeRemoveDialog();
    }


    render() {
      console.log("HistoryView renders");
      let props = this.props;
      return (
        <>
          <HistoryTable openNotesEditor={this.openNotesEditor} openRemoveDialog={this.openRemoveDialog} rows={this.store.historyView}  {...props} />
          <NotesEditor
            open={this.state.notesEditorOpened}
            notes={this.state.notes}
            onClose={this.onNotesEditorClosed}
          />
          {/**  диалог с удалением каритнки из базы*/}
          <DialogWindow
            open={this.state.dialogOpened}
            onClose={(action) => { this.onRemoveDbDialogClosed(action) }}
            title=""
            text={i18next.t('remove_db_dialog_text')}
            buttons={[i18next.t('no'), i18next.t('yes')]}
          />

        </>
      )
    }
  })

export default withStyles(styles)(HistoryView);
